import * as Phaser from 'phaser';
import { APP_HEIGHT } from '../../../Config';
import { DestroyableInterface } from '../../Interfaces/DestroyableInterface';
import { EnemyWaves } from '../EnemyWaves';
import { Player } from '../Player';


export interface EnemyBulletCreateInsterface {
    enemyWaves: EnemyWaves,
    type: string,
    scale: number,
    player: Player,
}

export class EnemyBullet extends Phaser.Physics.Arcade.Sprite implements DestroyableInterface {
    public takenDamageFrom: any;
    public health: number = 1;
    public damage: number = 25;
    private bullet: BulletData;
    private rotationOptions: {
        rotationSpeed: number,
        rotationDirection: number
    };
    private enemyWaves: EnemyWaves;

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string | Phaser.Textures.Texture, frame?: string | integer) {
        super(scene, x, y, texture, frame);
        this.setActive(false);
        this.setVisible(false);

        this.on('destroyed', () => {
            this.enemyWaves.createExplosion(this.x, this.y - this.displayHeight / 2, 'Assets/Explosion/explosion_0.1/explosion00')
                .setScale(this.scale * 2)
                .play('explosion')
                .on('animationcomplete', (animation: any, frame: any, sprite: Phaser.GameObjects.Sprite) => {
                    sprite.destroy();
                });
        });
    }

    create(config: EnemyBulletCreateInsterface): EnemyBullet {
        this.enemyWaves = config.enemyWaves;
        // seek_player
        this.bullet = this.enemyWaves.DATA_MANAGER.bullets.getByKey(config.type);

        this.setVelocity(0, this.bullet['velocity']);

        this.setScale(config.scale);
        // this.setScale(scale);
        return this;
    }

    createCustom(config: {
        enemyWaves: EnemyWaves,
        texture: string,
        scale: number,
    }): EnemyBullet {

        this.enemyWaves = config.enemyWaves;
        this.setTexture('atlas', config.texture);
        this.setScale(config.scale);
        config.enemyWaves.setTextureSize(this, config.texture);
        return this;
    }

    fire(x: number, y: number): EnemyBullet {
        this.setPosition(x, y - this.displayHeight / 2);

        this.setActive(true);
        this.setVisible(true);

        return this;
    }

    fireToPlayer(player: Player, rotation?: {
        rotationSpeed: number,
        rotationDirection: number
    }): EnemyBullet {
        if (rotation) {
            this.rotationOptions = rotation;
        }

        this.enemyWaves.setVelocityToPoint(this, { x: player.getX(), y: player.getY() }, this.bullet['velocity']);

        this.setActive(true);
        this.setVisible(true);


        return this;
    }

    update(time: number, delta: number) {
        if (this.y + this.displayHeight / 2 > APP_HEIGHT) {
            this.setActive(false);
            this.setVisible(false);
            this.destroy();
        } else {
            if (this.rotationOptions) {
                if (this.rotationOptions.rotationDirection === 1) {
                    this.rotation += this.rotationOptions.rotationSpeed;
                } else {
                    this.rotation -= this.rotationOptions.rotationSpeed;
                }
            }
        }
    }
}
