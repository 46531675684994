import * as Phaser from 'phaser';
import { HIT_COLOR_END, HIT_COLOR_START, HIT_DURATION_MS } from '../GameConfig';
export default class HitTint {
    private scene: Phaser.Scene;
    private sprite: any;
    private tweenStep: number = 0;
    private col: any;
    private tween: Phaser.Tweens.Tween;
    private completed: boolean = true;

    constructor(scene: Phaser.Scene, sprite: any) {
        this.scene = scene;
        this.sprite = sprite;
    }

    hit() {
        if (this.completed) {
            if (!this.tween) {
                this.completed = false;
                this.tweenStep = 0;
                this.tween = this.scene.tweens.add({
                    targets: this,
                    tweenStep: 100,
                    onUpdate: () => {
                        this.col = Phaser.Display.Color.Interpolate.ColorWithColor(HIT_COLOR_START, HIT_COLOR_END, 100, this.tweenStep);
                        this.sprite.tint = Phaser.Display.Color.GetColor(this.col.r, this.col.g, this.col.b);
                    },
                    onComplete: () => {
                        this.completed = true;
                    },
                    duration: HIT_DURATION_MS,
                    yoyo: true // Return to first tint
                });
            } else {
                this.completed = false;
                this.tween.play();
            }
        }
    }
}
