import React, { Component } from 'react';
import { imagePath } from '../../Tools/Functions';

class GoBack extends Component {

    render() {
        const { onClick } = this.props;

        return (
            <div className="menu-item OpenSans-ExtraBold" style={{
                position: 'absolute',
                top: '2.5vw',
                left: '2.5vw',
                fontSize: '5vw',
                width: '20vw',
                margin: 0,
                zIndex: 9999,
            }}
                onClick={onClick}
            >
                <img alt="" src={imagePath(require('../../Game/Assets/UI/ArrowLeft.png'))} style={{
                    width: '7.5vw',
                    heigh: 'auto',
                }} />
            </div>
        );
    }
}

export default GoBack;
