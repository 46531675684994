import { GAME_ASSETS_SCALE } from "../../Config";
import SpaceWrapper from "../Objects/Enemy/Boss/SpaceWrapper";
import { DataManager } from "./DataManager";

const rows = [
    {
        id: 1,
        key: 'space_wrapper',
        name: 'Space Wrapper',
        object: SpaceWrapper,
        texture: 'Assets/Enemies/SpaceWrapper/space_wrapper',
        health: 5000,
        damage: 1000,
        size: GAME_ASSETS_SCALE * 3,
        asteroidType: 'asteroid1'
    },
    {
        id: 2,
        key: 'asteroid1',
        name: 'Asteroid type 1',
        texture: [
            'Assets/Asteroids/01/asteroid1-00',
            'Assets/Asteroids/01/asteroid1-01',
            'Assets/Asteroids/01/asteroid1-02',
            'Assets/Asteroids/01/asteroid1-03',
            'Assets/Asteroids/01/asteroid1-04',
            'Assets/Asteroids/01/asteroid1-05',
        ],
        health: 100,
        damage: 100,
        size: 0.10,
    },
    {
        id: 3,
        key: 'asteroid2',
        name: 'Asteroid type 2',
        texture: [
            'Assets/Asteroids/02/asteroid2-00',
            'Assets/Asteroids/02/asteroid2-01',
            'Assets/Asteroids/02/asteroid2-02',
            'Assets/Asteroids/02/asteroid2-03',
        ],
        health: 100,
        damage: 100,
        size: 0.10,
    },

    {
        id: 4,
        key: 'triangle_alien',
        name: 'Trielien',
        texture: 'Assets/Enemies/triangle_alien',
        health: 500,
        damage: 100,
        size: 0.15,
        bullet: 'bullet_yellow_circle',
    },
];

export class EnemyData {
    public id: number;
    public key: string;
    public name: string;
    public object?: Object;
    public texture: Array<string> | string;
    public health: number;
    public damage: number;
    public size: number;

    constructor(props: typeof rows[number]) {
        Object.entries(props).forEach(([key, value]) => {
            this[key] = value;
        });

    }
}

export class EnemiesData {
    private enemies: Array<EnemyData>;
    private enemiesById: { [key: string]: EnemyData } = {};
    private enemiesByKey: { [key: string]: EnemyData } = {};
    private dataManager: DataManager;

    constructor(dataManager: DataManager) {
        this.dataManager = dataManager;


        this.enemies = rows.map(enemy => new EnemyData(enemy));
        this.enemies.forEach((enemy: EnemyData) => {
            this.enemiesById[String(enemy.id)] = enemy;
            this.enemiesByKey[enemy.key] = enemy;
        });
    }

    public getById(id: number | string): EnemyData {
        return this.enemiesById[String(id)];
    }

    public getByKey(key: string): EnemyData {
        return this.enemiesByKey[key];
    }
}
