export const APP_VERSION = '0.10';
export const APP_STORE = {};

var url = window.location.href.replace('/index.html', '').split('?')[0];
if (url.substr(-1) === '/') {
    url = url.substr(0, url.length - 1);
}
url += '/';
url = url.split('#')[0];
if (url.substr(-1) !== '/') {
    url = url + '/';
}

export const APP_BASE_PATH = url;

export const APP_WIDTH = window.innerWidth;
export const APP_HEIGHT = window.innerHeight;
export const APP_WIDTH_CENTER = APP_WIDTH / 2;
export const APP_HEIGHT_CENTER = APP_HEIGHT / 2;
export const GAME_TO_BAR_HEIGHT = APP_HEIGHT * 0.065;

// for 640 height it is 0.125, then calculate
export const GAME_ASSETS_SCALE = (APP_WIDTH / 360) * 0.095;
export const GAME_SPEED_FACTOR = APP_WIDTH / 360;
export const GAME_JOYSTICK_RADIUS = APP_WIDTH * 0.065;
export const GAME_GRID_X = 20;
export const GAME_GRID_Y = 40;
export const GAME_ES_SCALE = 0.50;

export const BEHAVIOUR_TYPE_SET = 'set';
export const BEHAVIOUR_TYPE_GROUP = 'group';
export const BEHAVIOUR_TYPE_REPEAT = 'repeat';
export const BEHAVIOUR_TYPE_BOSS = 'boss';