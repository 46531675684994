import React, { Component } from 'react';
import { APP_HEIGHT, APP_WIDTH } from '../../Config';
import { getShip } from '../../Game/GameConfig';
import { imagePath } from '../../Tools/Functions';
import GoBack from '../Parts/GoBack';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { BULLETS_DATA } from '../../Game/Data/Bullets';
import { DATA_MANAGER } from '../../Game/Data/DataManager';
import { createShip } from '../../Game/Ship';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import PlayerData from '../../Game/Objects/PlayerData';

class EngineeringShip extends Component {
    state = {
        modalGun: null,
        modalGunIndex: null,
        modalGunShow: false,
        modalGunRepeats: [],
    };

    clearGunModal() {
        this.setState({
            modalGun: null,
            modalGunIndex: null,
            modalGunShow: false,
            modalGunRepeats: [],
        });
    }

    updateGunSelectionRepeats() {
        const { id } = this.props;
        const {
            modalGun,
            modalGunIndex,
            modalGunRepeats
        } = this.state;

        const shipState = PlayerData.getShipState(id);
        const ship = createShip(id);
        const guns = ship.getGuns();
        if (undefined === guns[modalGunIndex]) {
            guns[modalGunIndex] = {
                id: modalGun.id,
            };
        }
        guns[modalGunIndex]['repeats'] = modalGunRepeats;
        shipState['guns'] = guns;
        PlayerData.updateShipState(id, shipState);
    }

    removeGunSelectionRepeats() {
        const { id } = this.props;
        const {
            modalGunIndex,
        } = this.state;

        const ship = createShip(id);
        const guns = ship.getGuns().filter((_, index) => {
            return index !== modalGunIndex;
        });

        const shipState = PlayerData.getShipState(id);
        shipState['guns'] = guns;
        PlayerData.updateShipState(id, shipState);
    }

    updateGunSelection(modalGunAddNewGunId) {
        const {
            modalGunIndex,
        } = this.state;

        const gun = DATA_MANAGER.bullets.getById(modalGunAddNewGunId);

        this.setState({
            modalGun: gun,
            modalGunIndex: modalGunIndex,
            modalGunShow: true,
            modalGunRepeats: [],
        });
    }

    render() {
        const { manager, id } = this.props;
        const {
            modalGun,
            modalGunShow,
        } = this.state;
        const ship = createShip(id);
        // BULLETS_DATA
        return (
            <div>
                <GoBack onClick={() => {
                    manager.setPage('engineering');
                }} />
                <img
                    src={imagePath(require('../../Game/Assets/UI/BigSun.png'))}
                    alt=""
                    width={APP_WIDTH}
                    height="auto"
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                    }}
                />
                <div className="OpenSans-ExtraBold text-color" style={{
                    paddingTop: document.getElementById('top-counter').clientHeight + APP_HEIGHT * 0.05,
                    paddingLeft: '5vw',
                    paddingRight: '5vw',
                }}>

                    <div style={{
                        textAlign: 'center',
                        marginBottom: '1vw',
                        fontSize: '5vw',
                    }}>
                        {ship.getName()}
                    </div>

                    <div>
                        Level: {ship.getLevel()}
                    </div>
                    <hr />
                    <div style={{
                        fontSize: '1.2em',
                    }}>
                        Attack
                    </div>

                    <div>
                        Ship damage: {ship.getDamage()}
                    </div>

                    <hr />

                    <div style={{
                        fontSize: '1.2em',
                    }}>
                        Defence
                    </div>

                    <div>
                        Shilds: {ship.getShilds()}
                    </div>
                    <div>
                        Regeneration: {ship.getShildsRegeneration()} ps
                    </div>
                    <div>
                        Bounce percent: {ship.getBounceLabel()}
                    </div>

                    <hr />


                    <div style={{
                        fontSize: '1.2em',
                    }}>
                        Energy
                    </div>

                    <div>
                        Capacity: {ship.getEnergy()}
                    </div>
                    <div>
                        Efficiency: {ship.getEfficiencyLabel()}
                    </div>
                    <div>
                        Generator: {ship.getGenerator()} eps (i)
                    </div>
                    <div>
                        Engine: {ship.getEngine()} eps
                    </div>

                    <hr />

                    <div style={{
                        fontSize: '1.2em',
                    }}>
                        Weapons
                    </div>

                    {this.weapons(ship)}
                    {modalGunShow && this.renderModalGun(modalGun, ship)}
                </div>

            </div>
        );
    }

    weapons(ship: Ship) {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={1}>
                        {Array(8).fill(0).map((_, key) => {
                            const gun = ship.getGunByIndex(key);
                            // console.log(key, gun)
                            return (
                                <Grid key={key} item xs={3} style={{
                                    border: '2px dotted white',
                                    position: 'relative',
                                }} onClick={() => {
                                    this.setState({
                                        modalGun: gun,
                                        modalGunIndex: key,
                                        modalGunShow: true,
                                        modalGunRepeats: gun ? [...gun.repeats] : [],
                                    });
                                }}>
                                    <div style={{
                                        marginTop: '100%',
                                    }}></div>
                                    <div>
                                        {gun && this.renderGunSlot(gun)}
                                        {!gun && (
                                            <AddBoxIcon style={{
                                                fontSize: '12vw',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%,-50%)',
                                            }} />
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderGunSlot(gun) {
        return (
            <Grid container spacing={0} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                height: '100%',
                width: '100%',
            }}>
                <Grid item xs={12} style={{
                    position: 'relative',
                }}>
                    <img src={imagePath(require('../../Game/' + gun['texture'] + '.png'))} style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        maxHeight: '80%',
                        width: 'auto',
                    }} />
                </Grid>

            </Grid>
        )
    }

    renderModalGun(gun, ship) {
        if (gun) {
            return this.renderModalGunEdit(gun);
        } else {
            return this.renderModalGunList(ship);
        }
    }

    renderModalGunEdit(gun) {
        const { modalGunRepeats } = this.state;
        // console.log(gun)
        const repeatGroups = DATA_MANAGER.bullets.getRepeatGroups(gun.id);
        // console.log(modalGunRepeats)
        return (
            <Dialog
                open={true}
                onClose={() => {
                    this.clearGunModal();
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    // width: '50%',
                    // height: '50%',
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {gun.name}
                        </ListSubheader>
                    }
                >

                    {repeatGroups.map((group) => {
                        const groupRepeatData = DATA_MANAGER.bullets.getRepeatsForGroup(gun.id, group);
                        const groupIds = groupRepeatData.map(repeatData => repeatData.id);

                        return groupRepeatData.map((repeat) => {

                            return (
                                <ListItem selected={modalGunRepeats.indexOf(repeat.id) >= 0} style={{
                                    textAlign: 'center',
                                }} onClick={() => {
                                    this.setState({
                                        modalGunRepeats: [
                                            ...modalGunRepeats.filter(mid => groupIds.indexOf(mid) === -1),
                                            ...[repeat.id]
                                        ]
                                    });
                                }}>
                                    {repeat.repeat.map((angle) => {
                                        return (
                                            <img src={imagePath(require('../../Game/' + gun['texture'] + '.png'))} style={{
                                                width: 'auto',
                                                height: '5vw',
                                                transform: 'rotate(' + angle + 'deg)',
                                                margin: '0 1vw',
                                                display: 'inline-block',
                                            }} />
                                        );
                                    })}
                                </ListItem>
                            );
                        })
                    })}




                </List>

                <DialogActions>
                    <Button onClick={() => {
                        this.updateGunSelectionRepeats();
                        this.clearGunModal();
                    }} color="primary" disabled={modalGunRepeats.length === 0}>
                        Modify
                    </Button>
                    <Button onClick={() => {
                        this.removeGunSelectionRepeats();
                        this.clearGunModal();
                    }} color="primary">
                        Remove
                    </Button>
                    <Button onClick={() => {
                        this.clearGunModal();
                    }} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }

    renderModalGunList(ship) {
        const {
            modalGunIndex,
        } = this.state;
        // const indexGun = ship.getGunByIndex(modalGunIndex);
        const usedGunsIds = ship.getGuns().map(gun => gun.id);
        const availableGuns = DATA_MANAGER.bullets.getPlayersAvailableGuns().filter(gun => usedGunsIds.indexOf(gun.id) === -1);

        return (
            <Dialog
                open={true}
                onClose={() => {
                    this.clearGunModal();
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    // width: '50%',
                    // height: '50%',
                }}
            >
                <DialogTitle>
                    {availableGuns.length ? 'Select gun' : 'No guns available'}
                </DialogTitle>

                <List>
                    {availableGuns.map((gun) => (
                        <ListItem button onClick={() => {
                            this.updateGunSelection(gun.id);

                        }} key={gun.key}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={imagePath(require('../../Game/' + gun['texture'] + '.png'))} style={{
                                        maxHeight: '100%',
                                        width: 'auto'
                                    }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={gun.name} />
                        </ListItem>
                    ))}

                </List>

                <DialogActions>
                    <Button onClick={() => {
                        this.clearGunModal();
                    }} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

export default EngineeringShip;
/**
  <Dialog
                open={true}
                onClose={() => {
                    this.setState({ modalGun: null });
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    // width: '50%',
                    // height: '50%',
                }}
            >
                <DialogTitle>{gun.name}</DialogTitle>
                <DialogContent style={{
                    width: '50vw',
                    height: '50vw',
                    position: 'relative',
                }}>
                    <Grid container spacing={0} style={{
                        height: '100%',
                    }}>
                        <Grid item xs={3} style={{
                            position: 'relative',
                            height: '100%',
                        }}>
                            <img src={imagePath(require('../../Game/' + gun['texture'] + '.png'))} style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                maxWidth: '100%',
                                height: 'auto',
                            }} />
                        </Grid>
                        <Grid item xs={9} style={{
                            position: 'relative',
                            height: '100%',
                        }}>
                            Speed: {gun.velocity}
                            Damage: {gun.energy}
                        </Grid>
                    </Grid>

                </DialogContent>

            </Dialog>
 */