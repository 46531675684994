import { DataManager } from "./DataManager";

const rows = [
    {
        id: 1,
        name: 'Speedster',
        texture: 'Assets/SpaceShip/01/Spaceship_default',
        level: 1,
        energy: 1000,
        damage: 1,
        shilds: 100,
        shildsRegeneration: 10,
        bounce: 0.01,
        efficiency: 0.50,
        generator: 150,
        engine: 10,
        guns: [
            {
                id: 3,
                repeats: [1],
            },
            {
                id: 4,
                repeats: [101],
            },
        ]
    },
];

export class ShipData {
    public id: number;
    public key: string;
    public name: string;
    public level: number;
    public texture: Array<string> | string;
    public energy: number;
    public damage: number;
    public shilds: number;
    public shildsRegeneration: number;
    public bounce: number;
    public efficiency: number;
    public generator: number;
    public engine: number;
    public guns: Array<{ id: number, repeat: Array<number> }>;

    constructor(props: typeof rows[number]) {
        Object.entries(props).forEach(([key, value]) => {
            this[key] = value;
        });

    }

    public getTexture(): string {
        return Array.isArray(this.texture) ? this.texture[0] : this.texture;
    }
}

export class ShipsData {
    private ships: Array<ShipData>;
    private shipsById: { [key: string]: ShipData } = {};
    private dataManager: DataManager;

    constructor(dataManager: DataManager) {
        this.dataManager = dataManager;

        this.ships = rows.map(bullet => new ShipData(bullet));
        this.ships.forEach((bullet: ShipData) => {
            this.shipsById[String(bullet.id)] = bullet;
        });
    }

    public get(): Array<ShipData> {
        return this.ships;
    }

    public getById(id: number | string): ShipData {
        return this.shipsById[String(id)];
    }
}
