import * as Phaser from 'phaser';
import { WARP_DURATION } from './GameConfig';
class GameAnimations {

    preload(scene: Phaser.Scene) {
        this.createAnimation(scene, 'thrust', 1, 36, 'Assets/Thruster/Thruster_', 500, false, -1, false);
        this.createAnimation(scene, 'explosion', 0, 8, 'Assets/Explosion/explosion_0.1/explosion', 35, true, 0, false);

        this.createAnimation(scene, 'explosion_2', 0, 35, 'Assets/Explosion/explosion_2.1/ToonExplosion2_00', 50, true, 0, false);
        this.createAnimation(scene, 'explosion_6', 0, 36, 'Assets/Explosion/explosion_6.1/ToonExplosion6_00', 100, true, 0, false);
        // this.createAnimation(scene, 'enemy-ship-1', 0, 13, 'enemy-ship-1', 500, false, 0, false);
        this.createAnimation(scene, 'rocket-thrust', 0, 6, 'Assets/Rocket/thrust-', 500, false, -1, false);
        this.createAnimation(scene, 'warp', 1, 64, 'Assets/Warp/warp2/portal', WARP_DURATION, true, 0, false);
        this.createAnimation(scene, 'coin', 1, 36, 'Assets/Coin/coin_', 1000, false, -1, false);

        scene.anims.create({
            key: 'Assets/SpaceShip/01/Spaceship_left',
            frames: [
                { key: 'atlas', frame: 'Assets/SpaceShip/01/Spaceship_left01' },
                { key: 'atlas', frame: 'Assets/SpaceShip/01/Spaceship_left02' },
                { key: 'atlas', frame: 'Assets/SpaceShip/01/Spaceship_left03' },
            ],
            duration: 100,
            repeat: 1,
            hideOnComplete: false,
        });

        scene.anims.create({
            key: 'Assets/SpaceShip/01/Spaceship_right',
            frames: [
                { key: 'atlas', frame: 'Assets/SpaceShip/01/Spaceship_right01' },
                { key: 'atlas', frame: 'Assets/SpaceShip/01/Spaceship_right02' },
                { key: 'atlas', frame: 'Assets/SpaceShip/01/Spaceship_right03' },
            ],
            duration: 100,
            repeat: 1,
            hideOnComplete: false,
        });


        // scene.anims.create({
        //     key: 'enemy-ship-1-fire',
        //     frames: [
        //         { key: 'enemy-ship-1-13', frame: '' },
        //         { key: 'enemy-ship-1-12', frame: '' },
        //         { key: 'enemy-ship-1-11', frame: '' },
        //         { key: 'enemy-ship-1-10', frame: '' },
        //         { key: 'enemy-ship-1-11', frame: '' },
        //         { key: 'enemy-ship-1-12', frame: '' },
        //         { key: 'enemy-ship-1-13', frame: '' },
        //     ],
        //     duration: 100,
        //     // repeat: -1
        //     hideOnComplete: false,
        // });
    }

    createAnimation(scene: Phaser.Scene, name: string, start: number, length: number, assetName: string, duration: number, hideOnComplete: boolean, repeat: number, yoyo: boolean) {
        const frames: any = [];
        for (var i = start; i <= length; i++) {
            frames.push({ key: 'atlas', frame: assetName + (i < 10 ? '0' + i : i) })
        }
        scene.anims.create({
            key: name,
            frames: frames,
            duration: duration,
            repeat: repeat,
            hideOnComplete: hideOnComplete,
            yoyo: yoyo,

        });
    }

}

export default new GameAnimations();