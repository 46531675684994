import * as Phaser from 'phaser';
import { APP_HEIGHT } from '../../Config';
import { EnemyWaves } from './EnemyWaves';
import { Player } from './Player';


export default class Stats {
    private scene: Phaser.Scene;
    private player: Player;
    private enemyWaves: EnemyWaves;
    private distance: number = 0;
    private distanceText: Phaser.GameObjects.Text;
    private fpsText: Phaser.GameObjects.Text;
    private enemyText: Phaser.GameObjects.Text;
    private objectsText: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene, player: Player, enemyWaves: EnemyWaves) {
        this.scene = scene;
        this.player = player;
        this.enemyWaves = enemyWaves;
    }

    create() {
        this.distanceText = this.scene.add.text(10, APP_HEIGHT - 25, 'D 0');
        this.fpsText = this.scene.add.text(10, APP_HEIGHT - 50, 'FPS 60');
        this.enemyText = this.scene.add.text(10, APP_HEIGHT - 75, 'ENY 0');
        this.objectsText = this.scene.add.text(10, APP_HEIGHT - 100, 'OBJ ' + this.scene.children.length);
    }


    update(time: number, delta: number) {
        this.distance = time / 1000 * this.player.speed;
        this.distanceText.setText('D ' + parseFloat('' + this.distance).toFixed(2));

        this.enemyWaves.distance = this.distance;

        this.fpsText.setText('FPS ' + parseFloat('' + this.scene.game.loop.actualFps).toFixed(0));

        this.enemyText.setText('ENY ' + this.enemyWaves.getEnemyGroupSize());
        this.objectsText.setText('OBJ ' + this.scene.children.length);
    }


}
