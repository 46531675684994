import * as Phaser from 'phaser';
import { APP_HEIGHT } from '../../../Config';
import { rand } from '../../../Tools/Functions';
import { DestroyableInterface } from '../../Interfaces/DestroyableInterface';
import { EnemyWaves } from '../EnemyWaves';
import HitTint from '../HitTint';

export default class Asteroid extends Phaser.Physics.Arcade.Sprite implements DestroyableInterface {
    public initialHealth: number = 1;
    public health: number = this.initialHealth;
    public damage: number = 1;

    private speed = Phaser.Math.GetSpeed(50, 1);
    private rotationSpeed: number = 0;
    private rotationDirection: number = 0;
    private textures: Array<string> = [];
    private texturesSwap: Array<{ from: number, to: number, texture: string }> = [];
    private enemyWaves: EnemyWaves;
    private hitTint: HitTint;


    create(config: {
        enemyWaves: EnemyWaves,
        x: number,
        y: number,
        texture: string | Array<string>,
        health: number,
        damage: number,
        scale: number,
        velocity?: { x: number, y: number },
        origin?: Array<number>,
        rotationDirection?: number,
        rotation?: number,
        size: number,
        point?: { x: number, y: number, speed: number }
    }) {
        this.enemyWaves = config.enemyWaves;
        this.initialHealth = config.health;
        this.health = config.health;
        this.damage = config.damage;

        if (Array.isArray(config.texture)) {
            this.setTexture('atlas', config.texture[0]);
            this.textures = config.texture;

            const step = 1 / this.textures.length;
            this.textures.forEach((textureSwap: string, key: number) => {
                this.texturesSwap.push({
                    from: 1 - step * (key + 1),
                    to: 1 - step * key,
                    texture: textureSwap,
                });
            });
            // 1 / (this.textures.length - 1)

        } else {
            this.setTexture('atlas', config.texture);
        }

        this.setScale(config.scale);

        this.x = config.x;
        this.y = config.y;
        if (config.rotationDirection && config.rotation) {
            this.rotationDirection = config.rotationDirection;
            this.rotation = config.rotation;
        }

        if (config.point) {
            this.enemyWaves.setVelocityToPoint(this, { x: config.point.x, y: config.point.y }, config.point.speed);
        } else if (config.velocity) {
            this.setVelocity(config.velocity.x, config.velocity.y);
        } else {
            this.setVelocity((rand(1, 2) === 1 ? -1 : 1) * rand(0, 25), 50);
        }

        this.setBounce(1, 1);
        this.setCollideWorldBounds(true);

        this.rotationSpeed = Phaser.Math.GetSpeed(rand(1, 35), 1);
        // this.scene.physics.add.collider(this.scene.player.bullets, this.object, this.onCollision, undefined, this);

        if (config.origin) {
            this.x += config.origin[0] * this.displayWidth;
            this.y += config.origin[1] * this.displayHeight;
        }

        this.setActive(true);
        this.setVisible(true);

        this.on('destroyed', (object: DestroyableInterface) => {
            this.enemyWaves.createExplosion(this.x, this.y, 'Assets/Explosion/explosion_2.1/ToonExplosion2_0000')
                .setScale(2 * this.enemyWaves.getAssetScaleForPercent(1, this.displayWidth, 'Assets/Explosion/explosion_2.1/ToonExplosion2_0000'))
                .play('explosion_2')
                .on('animationcomplete', (animation: any, frame: any, sprite: Phaser.GameObjects.Sprite) => {
                    sprite.destroy();
                });
        });

        this.on('damaged', (object: DestroyableInterface) => {
            this.damaged(object);
        });

        return this;
    }

    update(time: number, delta: number) {
        if (this.active) {
            // this.y += this.speed * delta;
            if (this.y - this.displayHeight / 2 > APP_HEIGHT) {
                this.setActive(false);
                this.setVisible(false);
            }

            if (this.rotationDirection === 1) {
                this.rotation += this.rotationSpeed;
            } else {
                this.rotation -= this.rotationSpeed;
            }

            if (this.texturesSwap.length) {
                const damagePercent = this.health / this.initialHealth;

                this.texturesSwap.forEach((pair) => {
                    if (pair.from <= damagePercent && damagePercent <= pair.to) {
                        this.setTexture('atlas', pair.texture);
                    }
                });
            }

            if (this.getTopCenter().y > APP_HEIGHT) {
                this.destroy();
            }
        }
    }

    damaged(object: DestroyableInterface) {
        if (!this.hitTint) {
            this.hitTint = new HitTint(this.scene, this);
        }
        this.hitTint.hit();
    }
}
