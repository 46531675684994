import * as Phaser from 'phaser';
import { APP_HEIGHT } from '../../Config';

export interface HealthBarCreateInterface {
    x: number,
    y: number,
    width: number,
    health: number
}

export default class HealthBar extends Phaser.GameObjects.Container {
    public healthLeft: number;
    private health: number;
    private healthbar: Phaser.GameObjects.Rectangle;
    private healthbarBackground: Phaser.GameObjects.Rectangle;
    private barHeight: number = APP_HEIGHT * 0.005;
    private barWidth: number;

    create(config: HealthBarCreateInterface) {
        this.healthLeft = config.health;
        this.height = config.health;
        this.barWidth = config.width;
        this.health = config.health;

        this.healthbarBackground = this.scene.add.rectangle(
            config.x,
            config.y - this.barHeight,
            config.width,
            this.barHeight,
            0x8c0900
        );

        this.add(this.healthbarBackground);

        this.healthbar = this.scene.add.rectangle(
            config.x,
            config.y - this.barHeight,
            config.width,
            this.barHeight,
            0x009900
        );

        this.add(this.healthbar);

        return this;
    }

    update(time: number, delta: number) {
        if (this.healthLeft > 0) {
            this.healthbar.width = this.healthLeft / this.health * this.barWidth;
        } else {
            this.width = 0;
        }

    }
}


