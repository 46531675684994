import * as Phaser from 'phaser';
import { APP_HEIGHT, APP_WIDTH, APP_WIDTH_CENTER, GAME_ASSETS_SCALE } from '../../../../Config';
import { rand } from '../../../../Tools/Functions';
import { DestroyableInterface } from '../../../Interfaces/DestroyableInterface';
import { EnemyWaves } from '../../EnemyWaves';
import HealthBar from '../../HealthBar';
import { Hitbox } from '../../Hitbox';
import HitTint from '../../HitTint';

export default class SpaceWrapper extends Phaser.GameObjects.Container implements DestroyableInterface {
    public health: number = 5000;
    public damage: number = 1000000;

    private ship: Phaser.Physics.Arcade.Sprite;
    private hitbox: Hitbox;
    private enemyWaves: EnemyWaves;

    private asteroidSpeed: number = 200;
    private asterodInterval: number = 1500;
    private asteroidLastAdded: number = 0;
    private bulletSize: number = 0;
    private healthbar: HealthBar;
    private lineOfAsteroidInterval: number = 5000;
    private lineOfAsteroidLastAdded: number = 0;
    private hitTint: HitTint;
    private destoryActivated: boolean = false;
    private asteroid: any;
    private asteroidScale: number;
    private asteroidWidth: number;
    private astoridsContainerWidth: number;

    private bullets = {
        speed: 150,
        lastFired: 0,
        interval: (APP_HEIGHT / 150) * 1000,
        config: [
            {
                offsetX: 0,
                offsetY: 0,
                fire: [
                    APP_WIDTH_CENTER,
                    APP_HEIGHT,
                    150
                ],
            },
            {
                offsetX: -1.5,
                offsetY: 2,
                fire: [
                    APP_WIDTH_CENTER / 2,
                    APP_HEIGHT,
                    150
                ],
            },
            {
                offsetX: -3,
                offsetY: 1,
                fire: [
                    0,
                    APP_HEIGHT,
                    150
                ],
            },
            {
                offsetX: 1.5,
                offsetY: 2,
                fire: [
                    APP_WIDTH_CENTER * 1.5,
                    APP_HEIGHT,
                    150
                ],
            },
            {
                offsetX: 3,
                offsetY: 1,
                fire: [
                    APP_WIDTH,
                    APP_HEIGHT,
                    150
                ],
            },
        ],
    };

    create(config: {
        enemyWaves: EnemyWaves,
        x: number,
        y: number,
        scale: number,
        asteroidType: string
    }) {

        this.enemyWaves = config.enemyWaves;
        this.asteroid = config.enemyWaves.DATA_MANAGER.enemies.getByKey(config.asteroidType);
        this.asteroidScale = this.enemyWaves.getAssetScaleForPercent(0.10, APP_WIDTH, this.asteroid.texture);
        this.asteroidWidth = 0.10 * APP_WIDTH;
        this.astoridsContainerWidth = APP_WIDTH * 0.9;

        this.x = config.x;
        this.y = config.y;
        this.scale = config.scale;
        this.width = this.enemyWaves.getTextureWith('Assets/Enemies/SpaceWrapper/space_wrapper');
        this.height = this.enemyWaves.getTextureHeight('Assets/Enemies/SpaceWrapper/space_wrapper');

        this.ship = this.scene.physics.add.sprite(0, 0, 'atlas', 'Assets/Enemies/SpaceWrapper/space_wrapper');
        this.ship.setOrigin(0.5, 0.5);


        this.add(this.ship);

        this.scene.add.existing(this);

        this.healthbar = this.enemyWaves.createHealthBar({
            x: this.x,
            y: this.y - this.displayHeight / 2,
            width: this.displayWidth,
            health: this.health,
        });

        this.hitbox = this.enemyWaves.createHitbox({
            scale: this.scale,
            x: this.x,
            y: this.y + this.ship.displayHeight * 0.015,
            damage: this.damage,
            damageCallback: this.takeDamage.bind(this)
        });
        this.hitbox.setSize(this.ship.displayWidth * 0.85, this.ship.displayHeight / 10);

        this.hitTint = new HitTint(this.scene, this.ship);

        this.scene.events.on('update', this.update, this);

        return this;
    }

    takeDamage(object: DestroyableInterface) {
        this.health -= object.damage;
        this.hitTint.hit();
        if (this.health <= 0 && !this.destoryActivated) {
            this.destoryActivated = true;

            console.log('destroy')
            this.enemyWaves.createExplosion(this.x, this.y, 'explosion_6_00')
                .setScale(this.scale * 2)
                .play('explosion_6')
                .on('animationcomplete', (animation: any, frame: any, sprite: Phaser.GameObjects.Sprite) => {
                    sprite.destroy();
                });
            this.healthbar.destroy();
            this.hitbox.destroy();
            this.destroy();
        }
    }

    fireBullets() {
        let bullet;
        this.bullets.config.forEach((pair) => {

            // this.enemyWaves.jumpEnemy();
            bullet = this.enemyWaves.enemy_bullets.get().createCustom({
                enemyWaves: this.enemyWaves,
                texture: 'bullet_yellow_circle',
                scale: GAME_ASSETS_SCALE,
            });
            bullet.fire(
                this.x + (bullet.displayWidth * pair.offsetX),
                this.y + this.displayHeight / 2 - this.displayHeight * 0.1 + (pair.offsetY !== 0 ? bullet.displayHeight / pair.offsetY : 0),
            ).setVelocityToPointAndSpeed(pair.fire[0], pair.fire[1], pair.fire[2]);
        });
        bullet = undefined;
    }

    fireLineOfAsteroids() {
        const maxSize = 5;
        const displaySize = this.astoridsContainerWidth / maxSize;

        for (let i = 1; i <= maxSize; i++) {
            setTimeout(() => {
                this.fireAsteroid(
                    ((APP_WIDTH - this.astoridsContainerWidth) / 2) + i * displaySize - displaySize / 2,
                    this.y + (this.displayHeight / 2) + displaySize / 2,
                    rand(0, APP_WIDTH),
                    APP_HEIGHT,
                    this.asteroidSpeed
                );
            }, 100 * i);
        }
    }

    fireSingleAsteroidFromBase() {
        this.fireAsteroid(
            this.x,
            this.y + this.displayHeight / 2,
            this.enemyWaves.player.getX(),
            this.enemyWaves.player.getY(),
            this.asteroidSpeed
        );
    }

    fireAsteroid(x: number, y: number, toPointX: number, toPointY: number, speed: number) {
        if (!this.active) {
            return;
        }

        this.enemyWaves.jumpEnemy(this.enemyWaves.asteroids, {
            ...this.asteroid,
            x: x,
            y: y,
            scale: this.asteroidScale,
            health: 100,
            damage: 100,
            velocity: this.scene.physics.velocityFromRotation(Phaser.Math.Angle.Between(
                this.x, this.y,
                toPointX, toPointY
            ), rand(speed * 0.5, speed * 1.5)),
            origin: [0, -0.5]
        });
    }


    update(time: number, delta: number) {
        if (!this.active) {
            return;
        }
        this.healthbar.healthLeft = this.health;

        if (this.asteroidLastAdded === 0 || this.asteroidLastAdded < time) {
            this.asteroidLastAdded = time + this.asterodInterval;
            this.fireSingleAsteroidFromBase();
        }

        if (this.lineOfAsteroidLastAdded === 0) {
            this.lineOfAsteroidLastAdded = time + this.lineOfAsteroidInterval;
            this.fireLineOfAsteroids();
        } else if (this.lineOfAsteroidLastAdded < time) {
            this.lineOfAsteroidLastAdded = time + this.lineOfAsteroidInterval;
            this.fireLineOfAsteroids();
        }
    }
}
