import { BEHAVIOUR_TYPE_SET } from '../../../Config';

export const Level1Wave5 = {
    name: 'Wave 5 Boss',
    stages: [
        {
            enemies: [
                {
                    group: 'bosses',
                    type: 'space_wrapper',
                    behaviour: [
                        {
                            type: BEHAVIOUR_TYPE_SET,
                            time: 0,
                            x: 0.5,
                            y: 0.15,
                        },
                    ],
                },
            ],
        }
    ],
}