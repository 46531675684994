import { PlayerDataGun, PlayerDataInterface } from "../Interfaces/PlayerDataInterface";

const storage = window.localStorage;

const DATA_KEY_PREFIX = '_es_';

class PlayerData implements PlayerDataInterface {
    public coins: number = 0;
    public selectedShip: number = 1;

    private state = {};

    getShipState(shipId: number) {
        return this.getLocalStorageData('state')[shipId] || {};
    }

    updateShipState(shipId: number, shipState: Object) {
        const state = this.getLocalStorageData('state');
        state[shipId] = shipState;
        this.setLocalStorageData('state', state);
    }

    setLocalStorageData = (key: string, value: object) => {

        storage.setItem(DATA_KEY_PREFIX + key, JSON.stringify(value));
    }

    getLocalStorageData = (key: string) => {
        const data = storage.getItem(DATA_KEY_PREFIX + key);
        if (null !== data) {
            return JSON.parse(data);
        }
        return {};
    }
}

export default new PlayerData();