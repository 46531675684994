import * as Phaser from 'phaser';
import { WARP_DURATION } from '../../GameConfig';
import { EnemyWaves } from '../EnemyWaves';

let fps: number;
export default class JumpEnemy extends Phaser.GameObjects.Arc {
    // public speed = WARP_DURATION;
    protected animationDone: boolean = false;
    protected callbackDone: boolean = false;
    protected callback: CallableFunction;
    protected object: Phaser.GameObjects.Sprite;
    private time: number;
    private warp: Phaser.GameObjects.Sprite;

    createFromConfig(config: {
        enemyWaves: EnemyWaves,
        objectGroup: Phaser.GameObjects.Group
        x: number,
        y: number,
        size: number,
        texture: string,
        scale: number,
        origin?: Array<number>,
        rotation?: number,
    }, callback: CallableFunction) {

        if (config.origin && config.origin.length) {
            config.x += config.origin[0] * config.enemyWaves.getTextureWith(config.texture) * config.scale;
            config.y += config.origin[1] * config.enemyWaves.getTextureHeight(config.texture) * config.scale;
        }

        this.object = config.enemyWaves.jumpElements.get(config.x, config.y, 'atlas', config.texture);
        this.object.setScale(config.scale);

        this.object.alpha = 0;
        if (config.rotation) {
            this.object.rotation = config.rotation;
        }

        if (!fps) {
            fps = Math.floor(1000 / this.scene.anims.get('warp').frames.length * (1 / (WARP_DURATION / 1000)));
        }

        this.warp = config.enemyWaves.createPortal(config.x, config.y, 'Assets/Warp/warp2/portal01');
        this.warp.setScale((config.size / config.enemyWaves.getTextureWith('Assets/Warp/warp2/portal01')) * 2);
        this.warp.alpha = 1;
        this.warp.play('warp', true, fps);
        this.warp.on('animationcomplete', () => {
            callback(this);
        });
    }

    destroy() {
        this.warp.destroy();
        this.object.destroy();
        super.destroy();
    }

    update(time: number, delta: number) {
        this.time += delta;
        this.object.alpha += 1 / 1000 * delta;
        this.warp.alpha = 1 - this.object.alpha;
    }
}
