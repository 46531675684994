import { APP_HEIGHT, APP_WIDTH, BEHAVIOUR_TYPE_REPEAT } from '../../../Config';
import { rand } from '../../../Tools/Functions';

export const Level1Wave2 = {
    name: 'Wave 2',
    stages: [
        {
            enemies: [
                {
                    group: 'asteroids',
                    type: 'asteroid1',
                    behaviour: [
                        {
                            type: BEHAVIOUR_TYPE_REPEAT,
                            time: 0,
                            interval: 2000,
                            repeatTimes: 20,
                            x: [0.10, 0.90],
                            y: [0.10, 0.20],
                            rotationDirection: 1,
                            rotation: 1 * rand(1, 200) / 100,
                            point: { x: rand(APP_WIDTH * 0.4, APP_WIDTH * 0.6), y: APP_HEIGHT, speed: 200 },
                            health: 60,
                        },
                    ],
                },
            ],
        }
    ],
}