import React, { Component } from 'react';
import { APP_WIDTH } from '../../Config';
import { imagePath } from '../../Tools/Functions';

class PausePage extends Component {

    render() {
        const { manager, gameManager } = this.props;

        return (
            <div>
                <div style={{
                    width: '95vw',
                    height: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                }}>
                    <img
                        src={imagePath(require('../../Game/Assets/UI/Window.png'))}
                        alt=""
                        style={{
                            width: '100%',
                            height: 'auto',
                            opacity: '0.8',
                        }}
                    />

                    <div id="main-menu" style={{
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%,-40%)',
                        position: 'absolute',
                        textAlign: 'center',
                    }}>

                        <div className="menu-item OpenSans-ExtraBold" style={{
                            fontSize: '6vw',
                            position: 'relative',
                            display: 'inline-block',
                            padding: '2vw',
                        }} onClick={() => {
                            gameManager.resume();
                        }}>
                            <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                                width: '100%',
                                heigh: 'auto',
                                position: 'absolute',
                                top: 0,
                                left: 0,

                            }} />
                            Resume
                            <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                                width: '100%',
                                heigh: 'auto',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                            }} />
                        </div>

                        <br />

                        <div className="menu-item OpenSans-ExtraBold" onClick={() => {
                            manager.setPage('missions');
                        }} style={{
                            fontSize: '6vw',
                            position: 'relative',
                            display: 'inline-block',
                            padding: '2vw',
                        }}>
                            <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                                width: '100%',
                                heigh: 'auto',
                                position: 'absolute',
                                top: 0,
                                left: 0,

                            }} />
                            Settings
                            <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                                width: '100%',
                                heigh: 'auto',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                            }} />
                        </div>

                        <br />

                        <div className="menu-item OpenSans-ExtraBold" style={{
                            fontSize: '6vw',
                            position: 'relative',
                            display: 'inline-block',
                            padding: '2vw',
                        }} onClick={() => {
                            gameManager.endMission();
                            manager.setPage('home');
                        }}>
                            <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                                width: '100%',
                                heigh: 'auto',
                                position: 'absolute',
                                top: 0,
                                left: 0,

                            }} />
                            Quit
                            <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                                width: '100%',
                                heigh: 'auto',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                            }} />
                        </div>





                    </div>
                </div>


                <img
                    src={imagePath(require('../../Game/Assets/UI/BigSun.png'))}
                    alt=""
                    width={APP_WIDTH}
                    height="auto"
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                    }}
                />




            </div>
        );
    }
}

export default PausePage;
