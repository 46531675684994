import { DataManager } from "./DataManager";

const rows = [
    {
        id: 1,
        key: 'red-square-bullet',
        playerable: false,
        object: 'bullet',
        texture: 'Assets/red-square-bullet',
        velocity: 300,
        scale: 0.5,
        fireInterval: 1500,
    },
    {
        id: 2,
        key: 'bullet_yellow_circle',
        playerable: false,
        object: 'bullet',
        texture: 'Assets/Bullets/yellow-circle',
        velocity: 150,
        scale: 0.75,
        fireInterval: 3000,
    },
    {
        id: 3,
        key: 'player_red_bullet',
        name: 'Basic Red Phaser',
        playerable: true,
        object: 'bullet',
        texture: 'Assets/bullet-small',
        velocity: 500,
        size: 0.015,
        fireInterval: 300,
        energy: 5,
        repeats: [1, 2, 3, 4],
    },
    {
        id: 4,
        key: 'rocket_1',
        name: 'Fuel Rocket',
        playerable: true,
        object: 'rocket',
        texture: 'Assets/Rocket/rocket-1',
        velocity: 300,
        size: 0.035,
        fireInterval: 1000,
        ttl: 5000,
        energy: 10,
        repeats: [101, 102],
    },
    {
        id: 5,
        key: 'player_red_bullet2',
        name: 'Basic Red Phaser 2',
        playerable: true,
        object: 'bullet',
        texture: 'Assets/bullet-small',
        velocity: 500,
        size: 0.015,
        fireInterval: 300,
        energy: 5,
        repeats: [1, 2, 3, 4],
    },
];

export class BulletData {
    public id: number;
    public key: string;
    public name: string;
    public playerable: boolean;
    public object: string;
    public texture: Array<string> | string;
    public velocity: number;
    public size: number;
    public fireInterval: number;
    public ttl?: number;
    public energy?: number;
    public repeats: Array<number> = [];

    constructor(props: typeof rows[number]) {
        Object.entries(props).forEach(([key, value]) => {
            this[key] = value;
        });

    }

    public getTexture(): string {
        return Array.isArray(this.texture) ? this.texture[0] : this.texture;
    }
}

export class BulletsData {
    private bullets: Array<BulletData>;
    private bulletsById: { [key: string]: BulletData } = {};
    private bulletsByKey: { [key: string]: BulletData } = {};
    private dataManager: DataManager;

    constructor(dataManager: DataManager) {
        this.dataManager = dataManager;

        this.bullets = rows.map(bullet => new BulletData(bullet));
        this.bullets.forEach((bullet: BulletData) => {
            this.bulletsById[String(bullet.id)] = bullet;
            this.bulletsByKey[bullet.key] = bullet;
        });
    }

    public create(data: typeof rows[number]): BulletData {
        return new BulletData(data);
    }

    public getById(id: number | string): BulletData {
        return this.bulletsById[String(id)];
    }

    public getByKey(key: string): BulletData {
        return this.bulletsByKey[key];
    }

    public getPlayersAvailableGuns(): Array<BulletData> {
        return this.bullets.filter(bullet => bullet.playerable === true);
    }

    public getRepeatGroups(bulletId: number): Array<string> {
        return this.getById(bulletId).repeats.map((repeatId: number) => {
            return this.dataManager.repeats.getById(repeatId).group;
        }).filter((v, i, a) => a.indexOf(v) === i);
    }

    public getRepeatsForGroup(bulletId: number, group: string) {
        return this.getById(bulletId).repeats.map((repeatId: number) => {
            return this.dataManager.repeats.getById(repeatId);
        }).filter(item => item.group === group);
    }
}
