import * as Phaser from 'phaser';
import { APP_HEIGHT, APP_WIDTH } from '../../Config';
import { PlayerDataInterface } from '../Interfaces/PlayerDataInterface';
import { EnemyWaves } from './EnemyWaves';
import Scene from '../Scene/Scene';
import Ship from '../Ship';

export default class Hud {
    private scene: Scene;
    private enemyWaves: EnemyWaves;
    private coinBar: Phaser.GameObjects.Sprite;
    private coinIcon: Phaser.GameObjects.Sprite;
    private coinText: Phaser.GameObjects.Text;
    private coins: number = 0;
    private playerData: PlayerDataInterface;
    private pause: Phaser.GameObjects.Sprite;
    private energyEmpty: Phaser.GameObjects.Sprite;
    private energyFull: Phaser.GameObjects.Sprite;
    private energyFullHeight: number;
    private shipObject: Ship;

    constructor(scene: Scene, enemyWaves: EnemyWaves, playerData: PlayerDataInterface, shipObject: Ship) {
        this.scene = scene;
        this.enemyWaves = enemyWaves;
        this.playerData = playerData;
        this.shipObject = shipObject;
    }

    create() {


        this.pause = this.scene.add.sprite(APP_WIDTH * 0.015, APP_WIDTH * 0.015, 'atlas', 'Assets/UI/ArrowLeft');
        this.pause.setOrigin(0, 0);
        this.pause.setScale(this.enemyWaves.getAssetScaleForPercent(0.06, APP_WIDTH, 'Assets/UI/ArrowLeft'));
        this.pause.depth = 10000010;
        this.pause.setInteractive();
        this.pause.on('pointerdown', () => {
            this.scene.gameManager.pause();

        });
        this.coinIcon = this.scene.add.sprite(this.pause.x + this.pause.displayWidth + APP_WIDTH * 0.005, this.pause.y + this.pause.displayHeight / 2, 'atlas', 'Assets/Coin/coin_01');
        this.coinIcon.setOrigin(0, 0.5);
        this.coinIcon.setScale(this.enemyWaves.getAssetScaleForPercent(0.05, APP_WIDTH, 'Assets/Coin/coin_01'));
        this.coinIcon.depth = 10000010;

        this.coinText = this.scene.add.text(
            this.coinIcon.x + this.coinIcon.displayWidth + APP_WIDTH * 0.005,
            this.coinIcon.y,
            '' + this.playerData.coins
        );
        this.coinText.setOrigin(0, 0.5);

        // this.coinBar = this.scene.add.sprite(0, 0, 'coinage_bar');
        // const coinBarHeight = this.enemyWaves.getTextureHeight('coinage_bar');
        // const coinBarWidth = this.enemyWaves.getTextureWith('coinage_bar');

        // this.coinBar.displayHeight = GAME_TO_BAR_HEIGHT;
        // this.coinBar.displayWidth = coinBarWidth * (GAME_TO_BAR_HEIGHT / coinBarHeight);

        // this.coinBar.setOrigin(0, 0);
        // this.coinBar.depth = 10000050;

        // gameManager

        const ratio = 0.045 * APP_WIDTH / this.enemyWaves.getTextureWith('Assets/UI/Fuel_Bar_Fill')
        const padding = APP_WIDTH * 0.025;

        this.energyEmpty = this.scene.add.sprite(APP_WIDTH - padding, APP_HEIGHT - padding, 'atlas', 'Assets/UI/Fuel_Bar_Empty');
        this.energyEmpty.setOrigin(1, 1);
        this.energyEmpty.displayWidth = this.energyEmpty.displayWidth * ratio;
        this.energyEmpty.displayHeight = this.energyEmpty.displayHeight * ratio;

        this.energyFull = this.scene.add.sprite(APP_WIDTH - padding, APP_HEIGHT - padding, 'atlas', 'Assets/UI/Fuel_Bar_Fill');
        this.energyFull.setOrigin(1, 1);
        this.energyFull.displayWidth = this.energyFull.displayWidth * ratio;
        this.energyFull.displayHeight = this.energyFull.displayHeight * ratio;
        this.energyFull.alpha = 0.50;

        this.energyFullHeight = this.energyFull.displayHeight;
        this.energyFull.displayHeight = this.energyFull.displayHeight * this.shipObject.getAvailableEnergy() / this.shipObject.getEnergy();

    }

    update(time: number, delta: number) {
        this.coinText.text = '' + this.playerData.coins;
        this.energyFull.displayHeight = this.energyFullHeight * this.shipObject.getAvailableEnergy() / this.shipObject.getEnergy();
    }
}
