import React, { Component } from 'react';
import { APP_WIDTH } from '../../Config';
import { imagePath } from '../../Tools/Functions';
import MainMenu from '../Parts/MainMenu';

class HomePage extends Component {

    render() {
        const { manager } = this.props;

        return (
            <div>
                <img
                    alt=""
                    src={imagePath(require('../../Game/Assets/UI/Sun.png'))}
                    width={APP_WIDTH}
                    height={'auto'}
                    style={{
                        position: 'absolute',
                        top: '0%',
                        left: '50%',
                        transform: 'translate(-50%,-75%)',
                    }}
                />

                <img
                    alt=""
                    className="rotation"
                    src={imagePath(require('../../Game/Assets/UI/Planet1.png'))}
                    width={APP_WIDTH * 1.25}
                    height={'auto'}
                    style={{
                        position: 'absolute',
                        bottom: -APP_WIDTH * 1.25 / 1.75,
                        left: -APP_WIDTH * 0.25 / 2,
                    }}
                />

                <img
                    alt=""
                    src={imagePath(require('../../Game/Assets/UI/Planet1Overlay.png'))}
                    width={APP_WIDTH * 1.25}
                    height={'auto'}
                    style={{
                        position: 'absolute',
                        bottom: -APP_WIDTH * 1.25 / 1.75,
                        left: -APP_WIDTH * 0.25 / 2,
                    }}
                />

                <MainMenu manager={manager} />
            </div>
        );
    }
}

export default HomePage;
