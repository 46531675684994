import { BEHAVIOUR_TYPE_SET } from '../../../Config';
import { rand } from '../../../Tools/Functions';

export const Level1Wave6 = {
    name: 'Wave 6',
    stages: [
        {
            enemies: [
                {
                    group: 'triangle_alien',
                    type: 'triangle_alien',
                    behaviour: [
                        {
                            type: BEHAVIOUR_TYPE_SET,
                            time: 0,
                            x: [0.40, 0.60],
                            y: 0.15,
                            rotationDirection: 1,
                            rotation: 1 * rand(1, 200) / 100,
                        },
                    ],
                },
            ],
        }
    ],
}