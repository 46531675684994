import React, { Component } from 'react';
import { APP_WIDTH } from '../../Config';
import { imagePath } from '../../Tools/Functions';

class Energy extends Component {

    render() {

        return (
            <div id="top-counter" style={{
                width: APP_WIDTH * 0.40,
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translate(-50%, 0%)',
            }}>
                <img
                    alt=""
                    src={imagePath(require('../../Game/Assets/UI/TopCounter.png'))}
                    width={APP_WIDTH * 0.40}
                    height={'auto'}
                // style={{
                //     position: 'absolute',
                //     bottom: -APP_WIDTH * 1.25 / 1.75,
                //     left: -APP_WIDTH * 0.25 / 2,
                // }}
                />

                <span className="OpenSans-ExtraBold" style={{
                    position: 'absolute',
                    top: '1%',
                    left: '30%',
                    color: '#fefeff',
                    fontSize: '3.75vw',
                    letterSpacing: '0.5vw',
                    textTransform: 'uppercase',
                }}>Energy</span>

                <span className="OpenSans-ExtraBold" style={{
                    position: 'absolute',
                    bottom: '15%',
                    left: '34%',
                    color: '#fefeff',
                    fontSize: '3.75vw',
                    letterSpacing: '0.5vw',
                    fontStyle: 'italic',
                }}>00100</span>
            </div>
        );
    }
}

export default Energy;
