import React, { Component } from 'react';
import { APP_WIDTH } from '../../Config';
import { DATA_MANAGER } from '../../Game/Data/DataManager';
import { imagePath } from '../../Tools/Functions';
import GoBack from '../Parts/GoBack';

class EngineeringPage extends Component {

    selectShip = (id) => {
        this.props.manager.setPage('engineeringShip', { id });
    }

    render() {
        const { manager } = this.props;

        return (
            <div>
                <GoBack onClick={() => {
                    manager.setPage('home');
                }} />
                <img
                    src={imagePath(require('../../Game/Assets/UI/BigSun.png'))}
                    alt=""
                    width={APP_WIDTH}
                    height="auto"
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                    }}
                />

                <div style={{
                    paddingTop: '20vw'
                }}>
                    {DATA_MANAGER.ships.get().map(this.ship)}
                </div>


            </div>
        );
    }

    ship = (ship, key) => {
        return (
            <div key={key} style={{
                width: '60%',
                margin: '0 auto',
            }}>
                <div>
                    <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                        width: '100%',
                        heigh: 'auto',
                        // position: 'absolute',
                        // top: 0,
                        // left: 0,

                    }} />
                    <div className="OpenSans-ExtraBold text-color" style={{
                        textAlign: 'center',
                        marginBottom: '1vw',
                        fontSize: '5vw',
                    }}>
                        {ship.name}
                        {' '}
                        (1)
                    </div>
                    <img src={imagePath(require('../../Game/' + ship.getTexture() + '.png'))} alt={ship.name} style={{
                        width: '100%',
                        height: 'auto',
                    }} />

                    <div>
                        <div className="OpenSans-ExtraBold text-color" style={{
                            textAlign: 'center',
                            marginBottom: '1vw',
                            fontSize: '5vw',
                        }} onClick={() => {
                            this.selectShip(ship.id);
                        }}>
                            Manage
                    </div>
                    </div>

                    <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                        width: '100%',
                        heigh: 'auto',
                        // position: 'absolute',
                        // top: 0,
                        // left: 0,

                    }} />
                </div>
            </div>
        );
    }
}

export default EngineeringPage;
