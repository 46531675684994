import React, { Component } from 'react';
import { APP_HEIGHT, APP_WIDTH } from '../Config';
import { imagePath } from '../Tools/Functions';
import EngineeringPage from './Pages/Engineering';
import EngineeringShip from './Pages/EngineeringShip';
import HomePage from './Pages/HomePage';
import MissionsPage from './Pages/MissionsPage';
import PausePage from './Pages/PausePage';
import Energy from './Parts/Energy';


const pages = {
    home: {
        component: HomePage,
    },
    pause: {
        component: PausePage,
    },
    missions: {
        component: MissionsPage,
    },
    engineering: {
        component: EngineeringPage,
    },
    engineeringShip: {
        component: EngineeringShip,
    },
};

class PageManager extends Component {
    state = {
        page: 'home',
        pageParams: {},
    };

    setPage(page, pageParams) {
        this.setState({ page, pageParams });
    }

    componentDidMount() {
        console.log('mount ')
        this.props.gameManager.setPageManager(this);
    }

    render() {
        const page = pages[this.state.page];
        const PageComponent = page.component;
        const {
            gameManager
        } = this.props;
        const {
            pageParams,
        } = this.state;

        return (
            <div id="game-ui" style={{
                background: 'url(' + imagePath(require('../Game/Assets/UI/Background1.png')) + ') no-repeat center center fixed',
                width: APP_WIDTH,
                height: APP_HEIGHT,
                backgroundSize: 'cover',
                position: 'relative',
            }}>

                {React.createElement(
                    PageComponent,
                    {
                        manager: this,
                        gameManager,
                        ...pageParams
                    }
                )}
                <Energy />

            </div>
        );
    }
}

export default PageManager;
