import * as Phaser from 'phaser';
import { BulletData } from '../../Data/Bullets';
import { DestroyableInterface } from '../../Interfaces/DestroyableInterface';
import { EnemyWaves, EnemyWavesCreateInterface } from '../EnemyWaves';

export default class Circler extends Phaser.Physics.Arcade.Sprite implements DestroyableInterface {
    public health: number = 1;
    public damage: number = 1;

    private lastFire: number = 0;
    private canFire: boolean = false;
    private bullet: BulletData;
    private enemyWaves: EnemyWaves;
    private rotationSpeed: number = Phaser.Math.GetSpeed(25, 1);
    private rotationDirection: number = 0;

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string | Phaser.Textures.Texture, frame?: string | integer) {
        super(scene, x, y, 'triangle_alien', frame);
    }

    create(config: EnemyWavesCreateInterface) {
        this.bullet = config.enemyWaves.DATA_MANAGER.bullets.getByKey(config.bullet);
        this.enemyWaves = config.enemyWaves;

        this.health = config.health;
        this.damage = config.damage;

        this.setTexture(config.texture);
        this.setScale(config.scale);

        this.x = config.x;
        this.y = config.y;
        this.setActive(true);
        this.setVisible(true);
        // this.setVelocity(0, 5);

        if (config.rotationDirection && config.rotation) {
            this.rotationDirection = config.rotationDirection;
            this.rotation = config.rotation;
        }
        this.canFire = true;
    }

    update(time: number, delta: number) {
        if (this.active) {
            if (this.rotationDirection === 1) {
                this.rotation += this.rotationSpeed;
            } else {
                this.rotation -= this.rotationSpeed;
            }

            if (this.canFire && time >= this.lastFire) {

                this.lastFire = time + this.bullet['fireInterval'];
                this.enemyWaves.enemy_bullets
                    .create(this.x, this.y, this.bullet.getTexture())
                    .create({
                        enemyWaves: this.enemyWaves,
                        type: this.bullet.key,
                        scale: this.scale,
                        player: this.enemyWaves.player
                    })
                    .fireToPlayer(this.enemyWaves.player, {
                        rotationSpeed: this.rotationSpeed,
                        rotationDirection: this.rotationDirection,
                    })
                    .setDepth(this.depth + 1);

            }
        }
    }
}
