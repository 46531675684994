import Ship from '../Ship';
import { EnemyWaves } from './EnemyWaves';
import { Player } from './Player';


export class PlayerGuns {

    private enemyWaves: EnemyWaves;
    private player: Player;
    private guns: Array<any> = [];
    private intervals: Array<number> = [];
    private shipObject: Ship;

    constructor(enemyWaves: EnemyWaves, shipObject: Ship) {
        this.enemyWaves = enemyWaves
        this.shipObject = shipObject;
    }

    public setPlayer(player: Player) {
        this.player = player;
        this.setGuns();
    }

    setGuns() {
        this.guns = [];
        let bullet: any;
        let repeatAngles: Array<number>;

        this.shipObject.getGuns().forEach((pair: any, index: number) => {
            bullet = this.shipObject.getGunByIndex(index);
            // console.log(bullet)
            // bullet = this.enemyWaves.DATA_MANAGER.bullets.getByKey(pair.type);
            // TODO MODIFIERS
            repeatAngles = [];
            bullet.repeats.forEach((repeatId: number) => {
                repeatAngles = [
                    ...repeatAngles,
                    ...this.enemyWaves.DATA_MANAGER.repeats.getById(repeatId).repeat
                ];
            });

            this.guns.push({
                ...bullet,
                ...{ repeat: repeatAngles }
            });
            this.intervals[this.guns.length - 1] = bullet.fireInterval;
        });

    }

    fire(gunConfig: any) {

        if (!this.shipObject.hasEnergy(gunConfig.energy * gunConfig.repeat.length)) {
            return;
        }

        this.shipObject.drawEnergy(gunConfig.energy * gunConfig.repeat.length);

        if (gunConfig.object === 'bullet') {
            gunConfig.repeat.forEach((angle: number) => {
                this.enemyWaves.player.bullets
                    .get(
                        this.enemyWaves.player.ship.x,
                        this.enemyWaves.player.ship.y + this.enemyWaves.player.body.displayHeight / 2,
                        'atlas',
                        this.enemyWaves.getTextureKey(gunConfig.texture)
                    )
                    .create({ ...gunConfig, angle, enemyWaves: this.enemyWaves })
                    .fire();
            });
        } else if (gunConfig.object === 'rocket' && this.enemyWaves.hasTarget()) {
            gunConfig.repeat.forEach((angle: string) => {
                this.enemyWaves.player.rockets.get(this.enemyWaves.player.ship.x, this.enemyWaves.player.ship.y).create({
                    ...gunConfig,
                    enemyWaves: this.enemyWaves,
                    x: this.enemyWaves.player.ship.x,
                    y: this.enemyWaves.player.ship.y,
                    angle,
                });
            });
        }


    }

    fireUpdate(time: number, delta: number) {
        this.guns.forEach((gunConfig: any, key: number) => {
            if (this.intervals[key] <= 0) {
                this.intervals[key] = gunConfig.fireInterval;
                this.fire(gunConfig);
            }
            this.intervals[key] -= delta;
        });
    }
}
