import { DataManager } from "./DataManager";

export const REPEAT_GROUP_FRONT = 'front';
export const REPEAT_GROUP_SIDE = 'side';

const rows = [
    {
        id: 1,
        group: REPEAT_GROUP_FRONT,
        name: '3 way front spread',
        repeat: [360 - 2, 0, 2],
    },
    {
        id: 2,
        group: REPEAT_GROUP_FRONT,
        name: '5 way front spread',
        repeat: [360 - 4, 360 - 2, 0, 2, 4],
    },
    {
        id: 3,
        group: REPEAT_GROUP_FRONT,
        name: '7 way front spread',
        repeat: [360 - 6, 360 - 4, 360 - 2, 0, 2, 4, 6],
    },
    {
        id: 4,
        group: REPEAT_GROUP_FRONT,
        name: '9 way front spread',
        repeat: [360 - 8, 360 - 6, 360 - 4, 360 - 2, 0, 2, 4, 6, 8],
    },
    {
        id: 101,
        group: REPEAT_GROUP_SIDE,
        name: '2 side rockets',
        repeat: [360 - 75, 75],
    },
    {
        id: 102,
        group: REPEAT_GROUP_SIDE,
        name: '2 side rockets',
        repeat: [360 - 85, 360 - 75, 75, 85],
    },
];

export class Repeat {
    public id: number;
    public group: string;
    public name: string;
    public repeat: Array<number>

    constructor(props: typeof rows[number]) {
        Object.entries(props).forEach(([key, value]) => {
            this[key] = value;
        });

    }
}

export class RepeatsData {
    private repeats: Array<Repeat>;
    private repeatsById: { [key: string]: Repeat } = {};
    private dataManager: DataManager;

    constructor(dataManager: DataManager) {
        this.dataManager = dataManager;

        this.repeats = rows.map(repeat => new Repeat(repeat));
        this.repeats.forEach((repeat: Repeat) => {
            this.repeatsById[String(repeat.id)] = repeat;
        });
    }

    public get(): Array<Repeat> {
        return this.repeats;
    }

    public getById(id: number | string): Repeat {
        return this.repeatsById[String(id)];
    }

}
