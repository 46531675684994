import * as Phaser from 'phaser';
import { APP_HEIGHT, APP_WIDTH } from '../Config';
import PageManager from '../UI/PageManager';
import { MISSIONS } from './GameConfig';
import GameScene, { SCENE_KEY } from './Scene/GameScene';

/**
 
1

I found another solution, for mobile I resize canvas instead of change camera zoom:

this.scene.scale.parent.width = Math.round(width);
this.scene.scale.parent.height = Math.round(height);

this.scene.scale.canvas.style.width = Math.round(width) + 'px';
this.scene.scale.canvas.style.height = Math.round(height) + 'px';
game.canvas.style.width = width + 'px';
game.canvas.style.height = height + 'px';

 */
export default class GameManager {
    game: Phaser.Game;
    gameConfig: Phaser.Types.Core.GameConfig;
    scene: GameScene;
    canvas: HTMLCanvasElement;
    ui: HTMLDivElement;
    onLoaded: CallableFunction;
    pageManager: PageManager;

    constructor(onLoaded: CallableFunction, ui: HTMLDivElement) {
        this.onLoaded = onLoaded;
        this.ui = ui;

        this.gameConfig = {
            title: 'Sample',

            type: Phaser.AUTO,

            scale: {
                width: APP_WIDTH,
                height: APP_HEIGHT,
            },

            physics: {
                default: 'arcade',
                arcade: {
                    debug: true,
                },
            },
            render: {
                antialias: false,
                pixelArt: true,
                roundPixels: true
            },
            // fps: {
            //     target: 200,
            //     forceSetTimeOut: true
            // },
            plugins: {

            },
            parent: 'game',
            backgroundColor: '#000000',
            // @TODO
            // resolution: window.devicePixelRatio,
            scene: [GameScene],
            // scene: new GameScene(this),
        };
        this.game = new Phaser.Game(this.gameConfig);
        this.game.events.on('GAME_SCENE_READY', this.loaded, this);
    }

    /**
     * API
     */

    public setPageManager(pageManger: PageManager) {
        this.pageManager = pageManger;

    }

    public loaded(scene: GameScene, canvas: HTMLCanvasElement) {
        this.scene = scene;
        this.canvas = canvas;
        this.showPages();
        this.onLoaded();
    }

    public startMission(key: number) {
        this.scene.endMission();
        this.showGame();
        this.scene.game.scene.resume(SCENE_KEY);
        this.scene.startMission(MISSIONS[key]);
    }

    public endMission() {
        this.scene.endMission();
    }

    public pause() {
        this.scene.game.scene.pause(SCENE_KEY);
        this.showPages();
        this.pageManager.setPage('pause');
    }

    public resume() {
        this.scene.game.scene.resume(SCENE_KEY);
        this.showGame();
    }

    public gameOver() {
        console.log('game over')
    }

    private showGame() {
        this.scene.game.scene.resume(SCENE_KEY);
        this.ui.style.display = 'none';
        this.canvas.style.display = 'initial';
    }

    private showPages() {
        this.scene.game.scene.resume(SCENE_KEY);
        this.canvas.style.display = 'none';
        this.ui.style.display = 'initial';
    }
}
