import * as Phaser from 'phaser';
import { APP_HEIGHT, APP_WIDTH, GAME_SPEED_FACTOR } from '../../Config';
import { getTextureHeight, getTextureWidth } from '../../Tools/Functions';


export class Background {
    private speed = Phaser.Math.GetSpeed(15 * GAME_SPEED_FACTOR, 1);
    private sideSpped = Phaser.Math.GetSpeed(150 * GAME_SPEED_FACTOR, 1);
    private starsSpeed = Phaser.Math.GetSpeed(50 * GAME_SPEED_FACTOR, 1);

    private scene: Phaser.Scene;
    private background: Phaser.GameObjects.TileSprite;
    private leftSide: Phaser.GameObjects.TileSprite;
    private rightSide: Phaser.GameObjects.TileSprite;
    private stars: Phaser.GameObjects.TileSprite;

    constructor(scene: Phaser.Scene) {
        this.scene = scene;
    }

    create() {

        this.background = this.scene.add.tileSprite(
            0,
            0,
            getTextureWidth('Assets/Backgrounds/blue_2'),
            getTextureHeight('Assets/Backgrounds/blue_2'),
            'atlas',
            'Assets/Backgrounds/blue_2'
        );
        this.background.setOrigin(0, 0);
        this.background.setDisplaySize(APP_WIDTH, APP_HEIGHT);
        this.background.alpha = 0.25;

        this.stars = this.scene.add.tileSprite(
            0,
            0,
            getTextureWidth('Assets/Backgrounds/stars-1'),
            getTextureHeight('Assets/Backgrounds/stars-1'),
            'atlas',
            'Assets/Backgrounds/stars-1'
        );
        this.stars.setOrigin(0, 0);
        this.stars.setDisplaySize(APP_WIDTH, APP_HEIGHT);
        this.stars.alpha = 0.9;


        this.leftSide = this.scene.add.tileSprite(
            0,
            0,
            getTextureWidth('Assets/Backgrounds/sidewall-left'),
            getTextureHeight('Assets/Backgrounds/sidewall-left'),
            'atlas',
            'Assets/Backgrounds/sidewall-left'
        );
        this.leftSide.alpha = 0.95;
        this.leftSide.setOrigin(0, 0);
        this.leftSide.setDisplaySize(APP_WIDTH * 0.025, APP_HEIGHT);

        this.rightSide = this.scene.add.tileSprite(
            APP_WIDTH - (APP_WIDTH * 0.025),
            0,
            getTextureWidth('Assets/Backgrounds/sidewall-right'),
            getTextureHeight('Assets/Backgrounds/sidewall-right'),
            'atlas',
            'Assets/Backgrounds/sidewall-right'
        );
        this.rightSide.alpha = 0.95;
        this.rightSide.setOrigin(0, 0);
        this.rightSide.setDisplaySize(APP_WIDTH * 0.025, APP_HEIGHT);

    }


    update(time: number, delta: number) {
        this.background.tilePositionY -= this.speed * delta;
        this.stars.tilePositionY -= this.starsSpeed * delta;
        this.leftSide.tilePositionY -= this.sideSpped * delta;
        this.rightSide.tilePositionY -= this.sideSpped * delta;
    }

}
