import * as Phaser from 'phaser';
import { DestroyableInterface } from '../Interfaces/DestroyableInterface';

export interface HitboxConfig {
    scale: number,
    x: number,
    y: number,
    damage: number,
    damageCallback: CallableFunction
};

export class Hitbox extends Phaser.Physics.Arcade.Sprite implements DestroyableInterface {
    public health: number = Number.MAX_SAFE_INTEGER;
    public damage: number = 0;

    private damageCallback: CallableFunction;

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string | Phaser.Textures.Texture, frame?: string | integer) {
        super(scene, x, y, 'empty', frame);

        this.on('damaged', (object: DestroyableInterface) => {
            this.damageCallback(object);
        });
    }

    create(config: HitboxConfig): Hitbox {
        this.scale = config.scale;
        this.x = config.x;
        this.y = config.y;
        this.damage = config.damage;
        this.damageCallback = config.damageCallback;

        return this;
    }
}
