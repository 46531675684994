import { APP_BASE_PATH } from "../Config";
const atlas = require('../Game/Atlas/texture.json');

export function imagePath(src) {
    if (undefined !== src['default']) {
        src = src['default'];
    }
    if (String(src).substr(0, 10) === 'data:image') {
        return src;
    }
    return APP_BASE_PATH + '.' + src;
}

export function rand(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function applyMixins(derivedCtor, baseCtors) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            if (name !== 'constructor') {
                derivedCtor.prototype[name] = baseCtor.prototype[name];
            }
        });
    });
}

export function getTextureWidth(key) {
    return atlas['frames'][key]['sourceSize']['w'];
}

export function getTextureHeight(key) {
    return atlas['frames'][key]['sourceSize']['h'];
}

export function getObjectProp(object, prop) {
    return object[prop];
}