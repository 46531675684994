import * as Phaser from 'phaser';
import { DestroyableInterface } from '../../Interfaces/DestroyableInterface';
import { EnemyWaves, EnemyWavesCreateInterface } from '../EnemyWaves';
import HealthBar from '../HealthBar';
import HitTint from '../HitTint';

export default class TriangleAlien extends Phaser.Physics.Arcade.Sprite implements DestroyableInterface {
    public health: number = 1;
    public damage: number = 1;

    private lastFire: number = 0;
    private canFire: boolean = false;
    private bullet: BulletData;
    private enemyWaves: EnemyWaves;
    private rotationSpeed: number = Phaser.Math.GetSpeed(25, 1);
    private rotationDirection: number = 0;
    private hitTint: HitTint;
    private healthbar: HealthBar;

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string | Phaser.Textures.Texture, frame?: string | integer) {
        super(scene, x, y, 'atlas', 'Assets/Enemies/triangle_alien');
    }

    create(config: EnemyWavesCreateInterface) {
        this.bullet = config.enemyWaves.DATA_MANAGER.bullets.getByKey(config.bullet);
        this.enemyWaves = config.enemyWaves;

        this.health = config.health;
        this.damage = config.damage;

        this.setTexture('atlas', config.texture);
        this.setScale(config.scale);

        this.x = config.x;
        this.y = config.y;
        this.setActive(true);
        this.setVisible(true);
        // this.setVelocity(0, 5);

        if (config.rotationDirection && config.rotation) {
            this.rotationDirection = config.rotationDirection;
            this.rotation = config.rotation;
        }

        this.canFire = true;

        this.healthbar = this.enemyWaves.createHealthBar({
            x: this.x,
            y: this.y - this.displayHeight / 2,
            width: this.displayWidth,
            health: this.health,
        });

        this.on('damaged', (object: DestroyableInterface) => {
            this.damaged(object);
        });

    }

    update(time: number, delta: number) {
        if (this.active) {
            this.healthbar.healthLeft = this.health;

            if (this.rotationDirection === 1) {
                this.rotation += this.rotationSpeed;
            } else {
                this.rotation -= this.rotationSpeed;
            }

            if (this.canFire && time >= this.lastFire) {

                this.lastFire = time + this.bullet['fireInterval'];
                this.enemyWaves.enemy_bullets
                    .create(this.x, this.y, 'atlas', this.bullet.getTexture())
                    .create({
                        enemyWaves: this.enemyWaves,
                        type: this.bullet.key,
                        scale: this.enemyWaves.getAssetScaleForPercent(0.2, this.displayWidth, this.bullet.getTexture()),
                        player: this.enemyWaves.player
                    })
                    .fireToPlayer(this.enemyWaves.player, {
                        rotationSpeed: this.rotationSpeed,
                        rotationDirection: this.rotationDirection,
                    })
                    .setDepth(this.depth - 1);

            }
        }
    }

    damaged(object: DestroyableInterface) {
        if (!this.hitTint) {
            this.hitTint = new HitTint(this.scene, this);
        }
        this.hitTint.hit();
    }

    destroy() {
        this.healthbar.destroy();
        super.destroy();
    }
}
