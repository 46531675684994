import { BulletsData } from './Bullets';
import { EnemiesData } from './Enemies';
import { RepeatsData } from './Repeats';
import { ShipsData } from './Ships';

export class DataManager {
    public bullets: BulletsData;
    public enemies: EnemiesData;
    public ships: ShipsData;
    public repeats: RepeatsData;

    constructor() {
        this.bullets = new BulletsData(this);
        this.enemies = new EnemiesData(this);
        this.ships = new ShipsData(this);
        this.repeats = new RepeatsData(this);
    }
}

export const DATA_MANAGER = new DataManager();

console.log(DATA_MANAGER)