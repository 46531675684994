import * as Phaser from 'phaser';
import { GAME_ASSETS_SCALE } from '../Config';
import { imagePath } from '../Tools/Functions';
import SpaceWrapper from './Objects/Enemy/Boss/SpaceWrapper';
import Ship from './Ship';
import { Level1Wave1 } from './Stages/Level1/Level1Wave1';
import { Level1Wave2 } from './Stages/Level1/Level1Wave2';
import { Level1Wave3 } from './Stages/Level1/Level1Wave3';
import { Level1Wave4 } from './Stages/Level1/Level1Wave4.';
import { Level1Wave5 } from './Stages/Level1/Level1Wave5';
import { Level1Wave6 } from './Stages/Level1/Level1Wave6';

export const WARP_DURATION = 1000;
export const NO_VELOCITY = [];
export const HIT_COLOR_START = Phaser.Display.Color.HexStringToColor('#ffffff');
export const HIT_COLOR_END = Phaser.Display.Color.HexStringToColor('#ff0000');
export const HIT_DURATION_MS = 13.333 * 2.5;
export const WAVE_CHECK_INTERVAL = 1000;
export const WAVE_MESSAGE_INTERVAL = 2000;

export const MISSIONS = [
    {
        name: 'Far blue station',
        planet: imagePath(require('./Assets/UI/Planet1.png')),
        overlay: imagePath(require('./Assets/UI/Planet1Overlay.png')),
        waves: [
            Level1Wave1,
            Level1Wave2,
            Level1Wave3,
            Level1Wave4,
            Level1Wave5,
            Level1Wave6,
        ],
    },
    {
        name: 'Facing a sun',
        planet: imagePath(require('./Assets/UI/Planet2.png')),
        overlay: imagePath(require('./Assets/UI/Planet2Overlay.png')),
        waves: [
            Level1Wave1,
            Level1Wave2,
            Level1Wave3,
            Level1Wave4,
            Level1Wave5,
            Level1Wave6,
        ],
    },
    {
        name: 'Moon of despair',
        planet: imagePath(require('./Assets/UI/Planet3.png')),
        overlay: imagePath(require('./Assets/UI/Planet3Overlay.png')),
        waves: [
            Level1Wave1,
            Level1Wave2,
            Level1Wave3,
            Level1Wave4,
            Level1Wave5,
            Level1Wave6,
        ],
    }
];
