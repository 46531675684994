import { BEHAVIOUR_TYPE_GROUP } from '../../../Config';

export const Level1Wave1 = {
    name: 'Wave 1',
    stages: [
        {
            enemies: [
                {
                    group: 'asteroids',
                    type: 'asteroid1',
                    behaviour: [
                        {
                            type: BEHAVIOUR_TYPE_GROUP,
                            time: 0,
                            x: 0.1,
                            y: 0.05,
                            width: 0.8,
                            sizeX: 10,
                            sizeY: 2,
                            spacing: 0.35,
                            health: 20,
                        },
                        {
                            type: BEHAVIOUR_TYPE_GROUP,
                            time: 3500,
                            x: 0.1,
                            y: 0.05,
                            width: 0.8,
                            sizeX: 10,
                            sizeY: 3,
                            spacing: 0.35,
                            health: 20,
                        },
                        {
                            type: BEHAVIOUR_TYPE_GROUP,
                            time: 8000,
                            x: 0.1,
                            y: 0.05,
                            width: 0.8,
                            sizeX: 10,
                            sizeY: 4,
                            spacing: 0.35,
                            health: 20,
                        },
                    ],
                },
            ],
        }
    ],
}