import * as Phaser from 'phaser';
import { APP_HEIGHT, APP_WIDTH, GAME_ASSETS_SCALE, GAME_SPEED_FACTOR } from '../../Config';
import { getTextureWidth } from '../../Tools/Functions';
import { PlayerDataInterface } from '../Interfaces/PlayerDataInterface';
import Ship from '../Ship';
import { Bullet } from './Bullet';
import { EnemyWaves } from './EnemyWaves';
import { PlayerGuns } from './PlayerGuns';
import { Rocket } from './Rocket';


export class Player {
    public speed = Phaser.Math.GetSpeed(GAME_SPEED_FACTOR * 500, 1);
    public bullets: Phaser.GameObjects.Group;
    public rockets: Phaser.GameObjects.Group;
    public collisionGroup: Phaser.GameObjects.Group;
    public ship: Phaser.GameObjects.Container;
    public partsCollection: Phaser.GameObjects.Arc;

    private scene: Phaser.Scene;

    public body: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody
    private thrust: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
    private shild: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody
    private shildRotationSpeed = Phaser.Math.GetSpeed(0.25, 1);

    private exaust1: Phaser.GameObjects.Image;

    private lastFiredBullets: number = 0;
    private enemyWaves: EnemyWaves;

    private boundaryOffset: number = 0.5 - 0.25;

    private thrustEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private playerGuns: PlayerGuns;
    private playerData: PlayerDataInterface;

    private point: { x: number, y: number } = {
        x: 0,
        y: 0,
    };

    private angle: number = 0;
    public angleDegrees: number | null = null;
    private shipObject: Ship;

    constructor(scene: Phaser.Scene, playerGuns: PlayerGuns, playerData: PlayerDataInterface, shipObject: Ship) {
        this.scene = scene;
        this.playerGuns = playerGuns;
        this.playerGuns.setPlayer(this);
        this.playerData = playerData;
        this.shipObject = shipObject;
    }

    getX() {
        return this.ship.x;
    }

    getY() {
        return this.ship.y;
    }

    create(enemyWaves: EnemyWaves) {
        this.enemyWaves = enemyWaves;
        this.ship = this.scene.add.container(APP_WIDTH / 2, APP_HEIGHT - getTextureWidth('Assets/SpaceShip/01/Spaceship_default') * 1.5);
        this.ship.depth = 10000010;

        this.thrust = this.scene.physics.add.sprite(0, 0, 'atlas', 'Assets/Thruster/Thruster_01');
        this.thrust.setScale(GAME_ASSETS_SCALE * 3);
        this.thrust.setOrigin(0.5, 0.5);
        this.thrust.y = this.thrust.displayHeight * 1.6;
        this.thrust.play('thrust');
        this.ship.add(this.thrust);

        this.body = this.scene.physics.add.sprite(0, 0, 'atlas', 'Assets/SpaceShip/01/Spaceship_default');
        this.body.setScale(GAME_ASSETS_SCALE * 3);
        this.body.setOrigin(0.5, 0.5);
        this.ship.add(this.body);


        var particles = this.scene.add.particles('atlas', 'Assets/Thruster/Thruster_01');

        this.thrustEmitter = particles.createEmitter({
            // frame: new Phaser.Textures.Frame('thrust-01'),
            scale: { start: this.thrust.scale, end: 0 },
            blendMode: 'ADD',
            // maxParticles: 10,
            lifespan: 50,
            // followOffset: new Phaser.Math.Vector2(-200, -200)
        });

        this.thrustEmitter.startFollow(this.ship, 0, this.thrust.displayHeight * 1.6);
        this.thrustEmitter.stop();


        this.shild = this.scene.physics.add.sprite(0, 0, 'atlas', 'Assets/Shilds/shild-1');
        // this.shild.setScale(GAME_ASSETS_SCALE * GAME_ES_SCALE);
        this.shild.setScale(GAME_ASSETS_SCALE * 1.5);
        this.shild.displayWidth = Math.max(this.body.displayWidth, this.body.displayHeight) * 1.45;
        this.shild.displayHeight = this.shild.displayWidth;
        this.shild.setOrigin(0.5, 0.5);
        this.shild.rotation = 0;
        this.ship.add(this.shild);

        this.ship.y = APP_HEIGHT - this.body.displayHeight * 1.5
        this.collisionGroup = this.scene.physics.add.group([
            this.body,
            this.shild
        ]);

        this.partsCollection = this.scene.add.arc(0, 0, this.body.displayWidth * 1.5);
        this.scene.physics.world.enable(this.partsCollection);

        this.bullets = this.scene.physics.add.group({
            classType: Bullet,
            // maxSize: 10,
            runChildUpdate: true
        });

        this.rockets = this.scene.physics.add.group({
            classType: Rocket,
            // maxSize: 10,
            runChildUpdate: true
        });


        this.scene.input.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
            this.point.x = pointer.x;
            this.point.y = pointer.y;
        });
    }


    update(time: number, delta: number) {
        this.shipObject.updateIncreasePower(
            delta,
            (
                this.scene.input.activePointer.isDown && (
                    this.scene.input.activePointer.x - this.point.x !== 0
                    ||
                    this.scene.input.activePointer.y - this.point.y !== 0
                )
            )
        );

        this.playerData.energy += Phaser.Math.GetSpeed(this.playerData.regeneration, 1) * delta * (this.scene.input.activePointer.isDown ? 0.5 : 1.5);

        if (this.playerData.energy > this.playerData.maxEnergy) {
            this.playerData.energy = this.playerData.maxEnergy;
        }

        if (this.scene.input.activePointer.isDown) {
            if (this.scene.input.activePointer.x - this.point.x <= -6) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_left03');
            } else if (this.scene.input.activePointer.x - this.point.x <= -4) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_left02');
            } else if (this.scene.input.activePointer.x - this.point.x <= -2) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_left01');
            } else if (this.scene.input.activePointer.x - this.point.x >= -1 && this.scene.input.activePointer.x - this.point.x <= 1) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_default');
            } else if (this.scene.input.activePointer.x - this.point.x >= 6) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_right03');
            } else if (this.scene.input.activePointer.x - this.point.x >= 4) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_right02');
            } else if (this.scene.input.activePointer.x - this.point.x >= 2) {
                this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_right01');
            }

            this.ship.x += (this.scene.input.activePointer.x - this.point.x);
            this.ship.y += this.scene.input.activePointer.y - this.point.y;

            this.point.x = this.scene.input.activePointer.x;
            this.point.y = this.scene.input.activePointer.y;

            this.thrust.visible = false;
            this.thrustEmitter.start();
        } else {
            this.body.setTexture('atlas', 'Assets/SpaceShip/01/Spaceship_default');
            this.thrust.visible = true;
            this.thrustEmitter.stop();
        }

        if (this.ship.y + this.ship.displayHeight / 2 > APP_HEIGHT) {
            this.ship.y = APP_HEIGHT - this.ship.displayHeight / 2;
        } else if (this.ship.y - this.ship.displayHeight / 2 < 0) {
            this.ship.y = this.ship.displayHeight / 2;
        }

        if (this.ship.y - this.boundaryOffset * this.body.displayHeight < 0) {
            this.ship.y = this.boundaryOffset * this.body.displayHeight;
        } else if (this.ship.y + this.boundaryOffset * this.body.displayHeight > APP_HEIGHT) {
            this.ship.y = APP_HEIGHT - this.boundaryOffset * this.body.displayHeight;
        }

        if (this.ship.x - this.boundaryOffset * this.body.displayWidth < 0) {
            this.ship.x = this.boundaryOffset * this.body.displayWidth;
        } else if (this.ship.x + this.boundaryOffset * this.body.displayWidth > APP_WIDTH) {
            this.ship.x = APP_WIDTH - this.boundaryOffset * this.body.displayWidth;
        }

        this.partsCollection.x = this.ship.x;
        this.partsCollection.y = this.ship.y;

        this.shild.rotation += delta * this.shildRotationSpeed;
        // this.thrust.x = this.ship.x;
        // this.thrust.y = this.ship.y + this.ship.displayHeight / 2 + this.thrust.displayHeight * 0.2;

        this.playerGuns.fireUpdate(time, delta);

        this.shipObject.updateUsePower(delta);
    }
}
