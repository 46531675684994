import * as Phaser from 'phaser';
import { APP_WIDTH, GAME_SPEED_FACTOR } from '../../Config';
import { DestroyableInterface } from '../Interfaces/DestroyableInterface';
export class Bullet extends Phaser.Physics.Arcade.Sprite implements DestroyableInterface {
    public takenDamageFrom: any;

    public health: number = 1;
    public damage: number = 10;

    private speedNumber = GAME_SPEED_FACTOR * 500;
    private speed = Phaser.Math.GetSpeed(this.speedNumber, 1);
    private vec: { x: number, y: number };

    create(config: any) {

        this.on('destroyed', () => {
            config.enemyWaves.createExplosion(this.x, this.y - this.displayHeight / 2, 'Assets/Explosion/explosion_0.1/explosion00')
                .setScale(2 * config.enemyWaves.getAssetScaleForPercent(1, this.displayWidth, 'Assets/Explosion/explosion_0.1/explosion00'))
                .play('explosion')
                .on('animationcomplete', (animation: any, frame: any, sprite: Phaser.GameObjects.Sprite) => {
                    sprite.destroy();
                });
        });


        if (config.angle) {
            if (config.angle <= 180) {
                this.setPosition(this.x + this.displayWidth / 2, this.y - this.displayHeight / 2);
            } else {
                this.setPosition(this.x - this.displayWidth / 2, this.y - this.displayHeight / 2);
            }

            this.vec = this.scene.physics.velocityFromAngle(config.angle, config.velocity * GAME_SPEED_FACTOR);
            this.setVelocity(this.vec.y, -this.vec.x);

            this.angle = config.angle;
        } else {
            this.setPosition(this.x, this.y - this.displayHeight / 2);
            this.setVelocity(0, -config.velocity * GAME_SPEED_FACTOR);
        }


        if (undefined !== config['scale']) {
            this.setScale(config['scale']);
        } else if (undefined !== config['size']) {
            this.setScale(config.enemyWaves.getAssetScaleForPercent(config['size'], APP_WIDTH, config['texture']));
        }

        return this;
    }

    fire(x: number, y: number, angle: number) {

        // this.setActive(true);
        // this.setVisible(true);
    }

    update(time: number, delta: number) {
        super.update(time, delta);

        if (this.y + this.displayHeight / 2 < 0) {
            this.destroy();
        }
    }
}
