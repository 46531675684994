import * as Phaser from 'phaser';
import { EnemyWaves } from './EnemyWaves';
import { GAME_SPEED_FACTOR } from '../../Config';
import { PlayerDataInterface } from '../Interfaces/PlayerDataInterface';

export default class Coin extends Phaser.Physics.Arcade.Sprite {
    private enemyWaves: EnemyWaves;
    private isCollected: boolean = false;
    private speed: number = 350 * GAME_SPEED_FACTOR;
    private playerData: PlayerDataInterface;

    create(enemyWaves: EnemyWaves, playerData: PlayerDataInterface) {
        this.enemyWaves = enemyWaves;
        this.playerData = playerData;
        this.depth = 0;
        this.on('collected', this.collected);
        return this;
    }

    update(time: number, delta: number) {
        if (!this.isCollected || !this.active) {
            return;
        }

        if (this.x >= this.enemyWaves.player.ship.x * 0.98
            && this.x <= this.enemyWaves.player.ship.x * 1.02
            && this.y >= this.enemyWaves.player.ship.y * 0.98
            && this.y <= this.enemyWaves.player.ship.y * 1.02) {
            this.playerData.coins++;
            this.destroy();

        } else {
            this.enemyWaves.setVelocityToPoint(
                this,
                { x: this.enemyWaves.player.ship.x, y: this.enemyWaves.player.ship.y },
                this.speed
            );
        }


    }

    collected() {
        if (this.isCollected) {
            return;
        }
        this.isCollected = true;
    }
}
