import * as Phaser from 'phaser';
import { imagePath } from '../Tools/Functions';

class GameAssets {

    preload(scene: Phaser.Scene) {
        scene.load.atlas('atlas', imagePath(require('./Atlas/texture.png')), require('./Atlas/texture.json'));





        // this.preloadImage(scene, 'ship-1', imagePath(require('./Assets/SpaceShip/Spaceship_default.png')));
        // this.preloadImage(scene, 'empty', imagePath(require('./Assets/empty.png')));
        // this.preloadImage(scene, 'coinage_bar', imagePath(require('./Assets/HUD/coinage_bar.png')));

        // this.preloadAnimaton(scene, 1, 36, 'thrust', './Assets/Thruster', 'Thruster_');



        // this.preloadImage(scene, 'bullet-small', imagePath(require('./Assets/bullet-small.png')));

        // // for (var a = 0; a <= 8; a++) {
        // //     // scene.load.image('explosion' + a, imagePath(require('./Assets/Explosion/explosion0' + a + '.png')));
        // // }
        // this.preloadAnimaton(scene, 0, 8, 'explosion', './Assets/Explosion', 'explosion');
        // this.preloadAnimaton(scene, 0, 35, 'explosion_2', './Assets/Explosion/explosion_2.1', 'ToonExplosion2_00');
        // this.preloadAnimaton(scene, 0, 36, 'explosion_6', './Assets/Explosion/explosion_6.1', 'ToonExplosion6_00');

        // this.preloadAnimaton(scene, 0, 13, 'enemy-ship-1', './Assets/enemy-ship-1', 'frame');

        // // scene.textures.addBase64('backgroind.blue', imagePath(require('./Assets/Backgrounds/blue.png')));

        // // scene.load.image('red-square-bullet', imagePath(require('./Assets/red-square-bullet.png')));

        // this.preloadImage(scene, 'red-square-bullet', imagePath(require('./Assets/red-square-bullet.png')));
        // // this.preloadImage(scene, 'rocket-1', imagePath(require('./Assets/rocket-1.png')));
        // this.preloadImage(scene, 'sidewall-left', imagePath(require('./Assets/Backgrounds/sidewall-left.png')));
        // this.preloadImage(scene, 'sidewall-right', imagePath(require('./Assets/Backgrounds/sidewall-right.png')));
        // this.preloadImage(scene, 'backgroind.blue_2', imagePath(require('./Assets/Backgrounds/blue_2.png')));
        // this.preloadImage(scene, 'stars-1', imagePath(require('./Assets/Backgrounds/stars-1.png')));

        // this.preloadImage(scene, 'rocket-1', imagePath(require('./Assets/Rocket/rocket-1.png')));
        // this.preloadImage(scene, 'rocket-2', imagePath(require('./Assets/Rocket/rocket-2.png')));
        // this.preloadAnimaton(scene, 0, 6, 'rocket-thrust', './Assets/Rocket', 'thrust-');

        // this.preloadAnimaton(scene, 0, 5, 'asteroid-1', './Assets/Asteroids/01', 'asteroid1-');
        // this.preloadAnimaton(scene, 0, 3, 'asteroid-2', './Assets/Asteroids/02', 'asteroid2-');

        // this.preloadImage(scene, 'triangle_alien', imagePath(require('./Assets/Enemies/triangle_alien.png')));
        // this.preloadImage(scene, 'circler', imagePath(require('./Assets/Enemies/circler.png')));
        // this.preloadImage(scene, 'circler_core', imagePath(require('./Assets/Enemies/circler_core.png')));
        // this.preloadImage(scene, 'space_wrapper', imagePath(require('./Assets/Enemies/SpaceWrapper/space_wrapper.png')));


        // this.preloadImage(scene, 'space_wrapper_body_bullet', imagePath(require('./Assets/Bullets/space_wrapper_body_bullet.png')));
        // this.preloadImage(scene, 'bullet_yellow_circle', imagePath(require('./Assets/Bullets/yellow-circle.png')));
        // this.preloadImage(scene, 'shild-1', imagePath(require('./Assets/Shilds/shild-1.png')));

        // // this.preloadAnimaton(scene, 0, 7, 'warp', './Assets/Warp', 'warp_');
        // this.preloadAnimaton(scene, 1, 64, 'warp', './Assets/Warp/warp2', 'portal');
        // this.preloadAnimaton(scene, 1, 36, 'coin', './Assets/Coin', 'coin_');



    }

}

export default new GameAssets();