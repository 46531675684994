import './App.css';
import React, { Component } from 'react';
import PageManager from './UI/PageManager';
import GameManager from './Game/GameManager';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';


const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  },
});

class App extends Component {
  gameManager: GameManager;
  state = {
    loading: true,
  };



  componentDidMount() {
    this.gameManager = new GameManager(() => {
      this.setState({ loading: false });
    }, document.getElementById('root'));
  }


  render() {
    const { loading } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          {loading && (<div>
            {'Loading...'}
          </div>)}
          {!loading && (<PageManager gameManager={this.gameManager} />)}
        </div>
      </ThemeProvider>

    );
  }

}



export default App;
