import * as Phaser from 'phaser';
import { APP_WIDTH, GAME_ASSETS_SCALE, GAME_SPEED_FACTOR } from '../../Config';
import { DestroyableInterface } from '../Interfaces/DestroyableInterface';
import { EnemyWaves } from './EnemyWaves';

export interface RocketCreateInterface {
    enemyWaves: EnemyWaves,
    x: number,
    y: number,
    angle: number,
    scale?: number,
    size?: number,
    texture: string,
    ttl: number,
    velocity: number,
}

export class Rocket extends Phaser.GameObjects.Container implements DestroyableInterface {
    public health: number = 1;
    public damage: number = 15;
    private speedNumber: number;
    private turnDegreesPerFrame: number;

    // private target: Phaser.Math.Vector2;
    private enemyWaves: EnemyWaves;

    private initTtl: number;
    private ttl: number;

    private rocket: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
    private thrust: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
    private fuelOut: boolean = false;
    private diff: number;
    private targetAngle: number;
    private cacheTarget: any;

    create(config: RocketCreateInterface) {
        this.active = true;
        this.visible = true;
        this.fuelOut = false;

        this.enemyWaves = config.enemyWaves;

        this.speedNumber = GAME_SPEED_FACTOR * config.velocity;
        this.turnDegreesPerFrame = GAME_SPEED_FACTOR * (config.velocity * 0.66) / (config.velocity / 2);

        this.initTtl = config.ttl;
        this.ttl = config.ttl;

        this.x = config.x;
        this.y = config.y;

        this.scale = GAME_ASSETS_SCALE * 4;

        if (undefined !== config['scale']) {
            this.setScale(config['scale']);
        } else if (undefined !== config['size']) {
            this.setScale(config.enemyWaves.getAssetScaleForPercent(config['size'], APP_WIDTH, config['texture']));
        }

        // private speedNumber: number = GAME_SPEED_FACTOR * 300;
        // private turnDegreesPerFrame: number = GAME_SPEED_FACTOR * 200 / 150;

        // rocket-thrust
        this.thrust = this.scene.physics.add.sprite(0, 0, 'atlas', 'Assets/Rocket/thrust-00');
        this.thrust.setOrigin(0.5, -1);
        this.thrust.play('rocket-thrust');
        // this.thrust.scale = this.scale * 1.25;

        this.add(this.thrust);

        this.rocket = this.scene.physics.add.sprite(0, 0, 'atlas', config['texture']);
        this.rocket.setOrigin(0.5, 0.5);

        this.add(this.rocket);

        this.rotation = Phaser.Math.DegToRad(config.angle);

        this.on('destroyed', () => {
            this.cacheTarget = null;
            this.playDestroy();
        });

        return this;
    }



    update(dt: number, delta: number) {
        super.update(dt, delta);

        if (this.ttl <= 0) {
            this.playDestroy();
            this.destroy();
            return;
        }
        this.ttl -= delta;

        if (!this.fuelOut) {
            this.cacheTarget = this.enemyWaves.getTarget();
            this.targetAngle = Phaser.Math.Angle.Between(
                this.x, this.y,
                this.cacheTarget.x, this.cacheTarget.y
            );

            // clamp to -PI to PI for smarter turning
            this.diff = Phaser.Math.Angle.Wrap(this.targetAngle - (this.rotation - Phaser.Math.DegToRad(90)))

            // set to targetAngle if less than turnDegreesPerFrame
            if (Math.abs(this.diff) < Phaser.Math.DegToRad(this.turnDegreesPerFrame)) {
                this.rotation = Phaser.Math.DegToRad(90) + this.targetAngle;
                // this.thrust.rotation = this.rotation;
            }
            else {
                let angle = this.angle - 90;
                if (this.diff > 0) {
                    // turn clockwise
                    angle += this.turnDegreesPerFrame
                }
                else {
                    // turn counter-clockwise
                    angle -= this.turnDegreesPerFrame
                }

                this.setAngle(angle + 90);
            }

            this.body.velocity.x = Math.cos(this.rotation - Phaser.Math.DegToRad(90)) * this.speedNumber;
            this.body.velocity.y = Math.sin(this.rotation - Phaser.Math.DegToRad(90)) * this.speedNumber;
        }

        this.thrust.setOrigin(0.5, -1 + (1 - (this.ttl * 0.75) / this.initTtl));
        if (this.ttl <= this.initTtl * 0.25) {
            this.fuelOut = true;
        }

    }

    playDestroy() {
        this.enemyWaves.createExplosion(this.x, this.y - this.displayHeight / 2, 'Assets/Explosion/explosion_0.1/explosion00')
            .setScale(this.scale / 4)
            .play('explosion')
            .on('animationcomplete', (animation: any, frame: any, sprite: Phaser.GameObjects.Sprite) => {
                sprite.destroy();
            });
    }
}
