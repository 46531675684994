import * as Phaser from 'phaser';
import GameAnimations from '../GameAnimations';
import GameAssets from '../GameAssets';
import { DestroyableInterface } from '../Interfaces/DestroyableInterface';
import { Background } from '../Objects/Background';
import { EnemyWaves } from '../Objects/EnemyWaves';
import Hud from '../Objects/Hud';
import { Player } from '../Objects/Player';
import PlayerData from '../Objects/PlayerData';
import Stats from '../Objects/Stats';
import GameManager from '../GameManager';
import { PlayerGuns } from '../Objects/PlayerGuns';
import { MISSIONS } from '../GameConfig';
import Ship, { createShip } from '../Ship';

export const SCENE_KEY: string = 'Game';

export const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {
    active: false,
    visible: false,
    key: SCENE_KEY,
};


export default class GameScene extends Phaser.Scene {
    public sceneStarted: boolean = false;
    public gameManager: GameManager;
    public player: Player;
    private background: Background;
    private bullets: Phaser.GameObjects.Group;
    private lastFiredBullets: number = 0;
    private enemyWaves: EnemyWaves;
    private stats: Stats;
    private hud: Hud;
    private playerData: any;
    private playerGuns: PlayerGuns;
    private shipObject: Ship;

    constructor(gameManager: GameManager) {
        super(sceneConfig);
        this.gameManager = gameManager;
    }

    gameOver() {
        this.gameManager.gameOver();
    }

    preload() {
        console.log('preload')
        GameAssets.preload(this);
    }


    public create() {
        this.game.events.emit('GAME_SCENE_READY', this, this.game.canvas);
        console.log('created')


    }

    public startMission(mission: typeof MISSIONS) {

        console.log('Start MISSION')
        this.sceneStarted = true;
        GameAnimations.preload(this);
        this.playerData = PlayerData;
        this.shipObject = createShip(this.playerData.selectedShip);

        // test.setScale(GAME_ASSETS_SCALE);
        this.physics.world.setBoundsCollision(true, true, false, false)
        // @TODO
        // this.physics.world.timeScale = 10;

        this.background = new Background(this);
        this.background.create();

        // var test = this.add.grid(0, 0, APP_WIDTH * 2, APP_HEIGHT * 2, APP_WIDTH / GAME_GRID_X, APP_HEIGHT / GAME_GRID_Y, 0x00b9f2).setAltFillStyle(0xffffff).setOutlineStyle();
        // test.alpha = 0.1;

        this.enemyWaves = new EnemyWaves(this, this.playerData);
        this.playerGuns = new PlayerGuns(this.enemyWaves, this.shipObject);
        this.player = new Player(this, this.playerGuns, this.playerData, this.shipObject);

        this.player.create(this.enemyWaves);
        this.enemyWaves.create(this.player, mission);

        this.stats = new Stats(this, this.player, this.enemyWaves);
        this.stats.create();

        this.hud = new Hud(this, this.enemyWaves, this.playerData, this.shipObject);
        this.hud.create();
    }

    public endMission() {
        this.children.destroy();

    }

    public onCollision(bullet: DestroyableInterface | any, enemy: DestroyableInterface | any) {
        if (bullet.active && enemy.active) {
            bullet.health -= enemy.damage;
            if (bullet.health <= 0) {
                bullet.emit('destroyed', enemy);
                bullet.destroy();
            } else {
                bullet.emit('damaged', enemy);
            }

            enemy.health -= bullet.damage;
            if (enemy.health <= 0) {
                enemy.emit('destroyed', bullet);
                this.enemyWaves.addCoin(enemy);
                enemy.destroy();
            } else {
                enemy.emit('damaged', bullet);
            }
        }

    }

    public onCollisionPlayer(bullet: DestroyableInterface | any, enemy: DestroyableInterface | any) {
        bullet.health -= enemy.damage;
        if (bullet.health <= 0) {
            bullet.emit('destroyed', enemy);
            bullet.destroy();
        } else {
            bullet.emit('damaged', enemy);
        }

        enemy.health -= bullet.damage;
        if (enemy.health <= 0) {
            enemy.emit('destroyed', enemy);
            enemy.destroy();
        } else {
            enemy.emit('damaged', enemy);
        }
    }

    public onCollection(playerCollection: any, collectable: any) {
        collectable.emit('collected');
    }

    public update(time: number, delta: number) {
        if (this.sceneStarted) {
            this.doSceneUpdate(time, delta);
        }

    }

    private doSceneUpdate(time: number, delta: number) {
        this.player.update(time, delta);
        this.stats.update(time, delta);
        this.background.update(time, delta);
        this.enemyWaves.update(time, delta);
        this.hud.update(time, delta);

        this.enemyWaves.getEnemyGroups().forEach((group: Phaser.GameObjects.Group) => {
            if (group.getLength() > 0) {
                this.physics.overlap(
                    this.player.bullets,
                    group,
                    this.onCollision,
                    undefined,
                    this
                );

                this.physics.overlap(
                    this.player.rockets,
                    group,
                    this.onCollision,
                    undefined,
                    this
                );
            }
        });


        this.enemyWaves.getCollectionGroups().forEach((group: Phaser.GameObjects.Group) => {
            if (group.getLength() > 0) {
                this.physics.overlap(
                    this.player.partsCollection,
                    group,
                    this.onCollection,
                    undefined,
                    this
                );
            }
        });



        if (this.enemyWaves.enemy_bullets.getLength() > 0) {
            this.physics.overlap(
                this.player.collisionGroup,
                this.enemyWaves.enemy_bullets,
                this.onCollisionPlayer,
                undefined,
                this
            );
        }
    }
}