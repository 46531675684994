import { DATA_MANAGER } from "./Data/DataManager";
import { ShipData } from "./Data/Ships";
import { SHIPS } from "./GameConfig";
import PlayerData from "./Objects/PlayerData";

let power = 0;
let energyAdd = 0;

export function createShip(id: number): Ship {
    return new Ship(DATA_MANAGER.ships.getById(id));
}

export default class Ship {
    private baseData: ShipData;
    private availableEnergy: number;
    private availableShilds: number;
    private shipState: any;

    /**
     * 
       level: 1,
        energy: 1000,
        damage: 1,
        shilds: 100,
        shildsRegeneration: 10,
        bounce: 0.01,
        efficiency: 0.50,
        generator: 100,
     */
    constructor(ship: ShipData) {

        this.baseData = ship;
        this.availableEnergy = this.getEnergy();
        this.availableShilds = this.getShilds();
        this.shipState = PlayerData.getShipState(ship.id);

        // setInterval(() => {
        //     console.log(power + ' / ' + energyAdd)
        //     energyAdd = 0
        //     power = 0;
        // }, 1000);
    }

    public updateIncreasePower(delta: number, isMoving: boolean = false) {
        // energyAdd += Phaser.Math.GetSpeed(this.getGenerator(), 1) * delta * this.getEfficiency();
        this.availableEnergy += Phaser.Math.GetSpeed(this.getGenerator(), 1) * delta * this.getEfficiency();
        if (isMoving) {
            this.availableEnergy -= delta * Phaser.Math.GetSpeed(this.getEngine(), 1);
        }
        if (this.availableEnergy > this.getEnergy()) {
            this.availableEnergy = this.getEnergy();
        }
    }

    public updateUsePower(delta: number) {
        /// @TODO add delta
        if (this.availableShilds < this.getShilds()) {
            console.log('Shild regenration')
            if (this.hasEnergy(this.getShildsRegeneration())) {
                this.drawEnergy(this.getShildsRegeneration());
                this.availableShilds += this.getShildsRegeneration();
            }
        }
    }

    public hasEnergy(energy: number): boolean {
        return this.availableEnergy >= energy;
    }

    public drawEnergy(energy: number): Ship {
        // power += energy;
        this.availableEnergy -= energy;
        return this;
    }

    public getAvailableEnergy(): number {
        return this.availableEnergy;
    }

    public getName(): string {
        return this.baseData.name;
    }

    public getLevel(): number {
        return this.baseData.level;
    }

    public getEnergy(): number {
        return this.baseData.energy;
    }

    public getDamage(): number {
        return this.baseData.damage;
    }


    public getShilds(): number {
        return this.baseData.shilds;
    }

    public getShildsRegeneration(): number {
        return this.baseData.shildsRegeneration;
    }

    public getBounce(): number {
        return this.baseData.bounce;
    }

    public getBounceLabel(): string {
        return Math.floor(parseFloat(this.getBounce().toFixed(2)) * 100) + '%';
    }

    public getEfficiency(): number {
        return this.baseData.efficiency;
    }

    public getEfficiencyLabel(): string {
        return Math.floor(parseFloat(this.getEfficiency().toFixed(2)) * 100) + '%';
    }

    public getGenerator(): number {
        return this.baseData.generator;
    }

    public getEngine(): number {
        return this.baseData.engine;
    }

    public getGuns() {
        if (undefined !== this.shipState['guns']) {
            return this.shipState['guns'];
        }
        return this.baseData.guns;
    }

    public getGunByIndex(index: number) {
        if (undefined !== this.getGuns()[index]) {
            return DATA_MANAGER.bullets.create({
                ...DATA_MANAGER.bullets.getById(this.getGuns()[index].id),
                ...this.getGuns()[index],
            });
        }
    }
}
