import * as Phaser from 'phaser';
import { APP_HEIGHT, APP_WIDTH, APP_WIDTH_CENTER, BEHAVIOUR_TYPE_GROUP, BEHAVIOUR_TYPE_REPEAT, BEHAVIOUR_TYPE_SET } from '../../Config';
import { getObjectProp, getTextureWidth, getTextureHeight, rand } from '../../Tools/Functions';
import { BulletData } from '../Data/Bullets';
import { DataManager, DATA_MANAGER } from '../Data/DataManager';
import { MISSIONS, WAVE_CHECK_INTERVAL, WAVE_MESSAGE_INTERVAL } from '../GameConfig';
import { EnemyWaveInterface } from '../Interfaces/EnemyWaveInterface';
import { PlayerDataInterface } from '../Interfaces/PlayerDataInterface';
import GameScene from '../Scene/GameScene';
import Coin from './Coin';
import Asteroid from './Enemy/Asteroid';
import Circler from './Enemy/Circler';
import CirclerBomber from './Enemy/CirclerBomber';
import { EnemyBullet } from './Enemy/EnemyBullet';
import EnemyShip1 from './Enemy/EnemyShip1';
import JumpEnemy from './Enemy/JumpEnemy';
import TriangleAlien from './Enemy/TriangleAlien';
import HealthBar, { HealthBarCreateInterface } from './HealthBar';
import { Hitbox, HitboxConfig } from './Hitbox';
import { Player } from './Player';

export interface EnemyWavesCreateInterface {
    x: number,
    y: number,
    texture: string,
    scale: number,
    health: number,
    damage: number,
    enemyWaves: EnemyWaves,
    bullet: string,
    rotationDirection?: number,
    rotation?: number,
};

export interface EnemyWaveMissionQueue {
    time: number,
    callback: string,
    data: Array<any>,
};

export class EnemyWaves {
    public DATA_MANAGER: DataManager;
    public player: Player;
    public boss: any;

    public explosions: Phaser.GameObjects.Group;
    public portals: Phaser.GameObjects.Group;

    public coins: Phaser.GameObjects.Group;

    public enemies: Phaser.GameObjects.Group;

    public asteroids: Phaser.GameObjects.Group;
    public enemy_ship_1: Phaser.GameObjects.Group;
    public triangle_alien: Phaser.GameObjects.Group;
    public circler: Phaser.GameObjects.Group;
    public circler_bomber: Phaser.GameObjects.Group;
    public bosses: Phaser.GameObjects.Group;

    public enemy_bullets: Phaser.GameObjects.Group;
    public healthBars: Phaser.GameObjects.Group;

    public distance: number = 0;
    private scene: GameScene;
    private jumps: Phaser.GameObjects.Group;
    public jumpElements: Phaser.GameObjects.Group;

    private wave: any;
    private lastWaveCheck = 0;
    private playerData: PlayerDataInterface;
    private waveStarted: boolean = false;
    private time: number;
    private delta: number;


    private target: any = {
        distance: 99999,
        object: {},
    };
    private defaultTarget: any;
    private mission: typeof MISSIONS;
    private waveNumber: number;
    private missionQueue: Array<EnemyWaveMissionQueue> = [];

    constructor(scene: GameScene, playerData: PlayerDataInterface) {
        this.DATA_MANAGER = DATA_MANAGER;
        this.scene = scene;
        this.playerData = playerData;
    }

    getGroup(group: any): Phaser.GameObjects.Group {
        return getObjectProp(this, group);
    }

    addEnemy(enemy: any) {
        this.scene.physics.add.existing(enemy);
        this.enemies.add(enemy);
        return enemy;
    }

    getEnemyGroups() {
        return [
            this.enemies,
            this.asteroids,
            this.enemy_ship_1,
            this.triangle_alien,
            this.circler,
        ];
    }

    getEnemyGroupSize(): number {
        let size = 0;
        this.getEnemyGroups().forEach((group: Phaser.GameObjects.Group) => {
            size += group.getLength();
        });
        return size;
    }

    getCollectionGroups() {
        return [
            this.coins,

        ];
    }

    createHitbox(config: HitboxConfig): Hitbox {
        return this.addEnemy((new Hitbox(this.scene, config.x, config.y, '')).create(config));
    }

    createHealthBar(config: HealthBarCreateInterface): HealthBar {
        return this.healthBars.get().create(config);
    }

    createExplosion(x: number, y: number, texture: string): Phaser.GameObjects.Sprite {
        return this.explosions.get(x, y, 'atlas', texture).setOrigin(0.5, 0.5);
    }

    createPortal(x: number, y: number, texture: string): Phaser.GameObjects.Sprite {
        return this.portals.get(x, y, 'atlas', texture).setOrigin(0.5, 0.5);
    }


    createCoin(x: number, y: number, texture: string): Phaser.GameObjects.Sprite {
        return this.coins.get(x, y, 'atlas', texture).create(this, this.playerData);
    }

    showTextForTime(x: number, y: number, string: string, time: number) {
        const text = this.scene.add.text(x, y, string);
        text.setOrigin(0.5, 0.5);
        setTimeout(() => {
            text.destroy();
        }, time);
    }

    create(player: Player, mission: typeof MISSIONS) {
        this.player = player;
        this.mission = mission;
        this.waveNumber = -1;
        this.missionQueue = [];

        this.defaultTarget = new Phaser.Math.Vector2(APP_WIDTH / 2, 0);
        this.defaultTarget.active = false;

        this.target.object = this.defaultTarget;

        this.healthBars = this.scene.physics.add.group({
            classType: HealthBar,
            runChildUpdate: true
        });


        this.enemies = this.scene.physics.add.group({
            runChildUpdate: true,
        });

        this.explosions = this.scene.physics.add.group({
            classType: Phaser.GameObjects.Sprite,
            runChildUpdate: true,
        });

        this.portals = this.scene.physics.add.group({
            classType: Phaser.GameObjects.Sprite,
            runChildUpdate: true,
        });


        this.coins = this.scene.physics.add.group({
            classType: Coin,
            runChildUpdate: true,
        });

        this.jumps = this.scene.physics.add.group({
            classType: JumpEnemy,
            runChildUpdate: true
        });

        this.jumpElements = this.scene.physics.add.group({
            classType: Phaser.GameObjects.Sprite,
            runChildUpdate: true
        });


        this.enemy_bullets = this.scene.physics.add.group({
            classType: EnemyBullet,
            runChildUpdate: true
        });


        this.asteroids = this.scene.physics.add.group({
            classType: Asteroid,
            runChildUpdate: true
        });

        this.enemy_ship_1 = this.scene.physics.add.group({
            classType: EnemyShip1,
            runChildUpdate: true
        });

        this.triangle_alien = this.scene.physics.add.group({
            classType: TriangleAlien,
            runChildUpdate: true
        });

        this.circler = this.scene.physics.add.group({
            classType: Circler,
            runChildUpdate: true
        });

        this.circler_bomber = this.scene.physics.add.group({
            classType: CirclerBomber,
            runChildUpdate: true
        });

        this.bosses = this.scene.physics.add.group({
            classType: Phaser.GameObjects.Container,
            runChildUpdate: false
        });


    }

    createRepeatBehaviour(behaviour: any, enemy: EnemyWaveInterface, time: number) {
        let behaviours = [];
        for (let r = 1; r <= behaviour.repeatTimes; r++) {
            behaviours.push({
                ...behaviour,
                ...{
                    type: BEHAVIOUR_TYPE_SET,
                    time: behaviour.time + time + ((r - 1) * behaviour.interval)
                }
            });
        }
        return behaviours;
    }

    createBehaviourGroup(behaviour: any, enemy: any) {
        const groupWidth = behaviour.width * APP_WIDTH;
        const spacePerObject = groupWidth / behaviour.sizeX;
        const groupX = behaviour.x * APP_WIDTH;
        const groupY = behaviour.y * APP_WIDTH;
        const objectScale = this.getAssetScaleForWidth(enemy, spacePerObject - spacePerObject * behaviour.spacing);
        const objectHeight = this.getTextureHeight(this.getTextureKey(enemy.texture)) * objectScale;

        let behaviours = [];
        for (let iy = 1; iy <= behaviour.sizeY; iy++) {
            for (let ix = 1; ix <= behaviour.sizeX; ix++) {

                behaviours.push({
                    ...enemy,
                    ...behaviour,
                    ...{
                        x: groupX + ix * spacePerObject - (spacePerObject / 2 + (spacePerObject * behaviour.spacing / 2)),
                        y: groupY + iy * (objectHeight + spacePerObject * behaviour.spacing) - (spacePerObject / 2 + (spacePerObject * behaviour.spacing / 2)),
                        scale: objectScale,
                    }
                });
            }
        }


        return behaviours;
    }


    queueMessage = (text: string) => {
        this.showTextForTime(APP_WIDTH_CENTER, APP_HEIGHT * 0.05, text, WAVE_MESSAGE_INTERVAL);
    }

    addBehaviour(behaviour: any, enemy: EnemyWaveInterface) {
        if (behaviour.type === BEHAVIOUR_TYPE_SET) {
            this.jumpEnemy(this.getGroup(enemy.group), {
                ...DATA_MANAGER.enemies.getByKey(enemy.type),
                ...behaviour,
                ...{
                    x: this.getBehaviourX(behaviour.x),
                    y: this.getBehaviourY(behaviour.y),
                    scale: this.getAssetScale(DATA_MANAGER.enemies.getByKey(enemy.type))
                }
            });
        } else if (behaviour.type === BEHAVIOUR_TYPE_GROUP) {
            this.createBehaviourGroup(behaviour, DATA_MANAGER.enemies.getByKey(enemy.type)).forEach((bconfig) => {
                this.jumpEnemy(this.getGroup(enemy.group), bconfig);
            });
        } else {
            console.error('Udefined behaviour type ' + behaviour.type)
        }
    }

    prepareWaveQueue(wave: any) {
        let lastQueueTime: number = 0;

        this.missionQueue.push({
            time: lastQueueTime,
            callback: 'queueMessage',
            data: [wave.name]
        });

        wave.stages.forEach((stage: any) => {
            stage.enemies.forEach((enemy: any) => {
                enemy.behaviour.forEach((behaviour: any) => {

                    if (behaviour.type === BEHAVIOUR_TYPE_REPEAT) {
                        this.createRepeatBehaviour(behaviour, enemy, lastQueueTime).forEach((repeatBehaviour) => {
                            if (repeatBehaviour.time > lastQueueTime) {
                                lastQueueTime = repeatBehaviour.time;
                            }
                            this.missionQueue.push({
                                time: parseInt(repeatBehaviour.time),
                                callback: 'addBehaviour',
                                data: [repeatBehaviour, enemy]
                            });
                        });
                    } else {
                        lastQueueTime += behaviour.time;
                        this.missionQueue.push({
                            time: lastQueueTime,
                            callback: 'addBehaviour',
                            data: [behaviour, enemy]
                        });
                    }
                });
            });
        });
    }


    asyncManageWaves() {
        this.manageWaves(this.time, this.delta);
    }

    manageWaves(time: number, delta: number) {
        if (!this.missionQueue.length && 0 === this.getEnemyGroupSize()) {
            this.waveNumber++;
            if (undefined !== this.mission.waves[this.waveNumber]) {
                this.prepareWaveQueue(this.mission.waves[this.waveNumber]);
            } else {
                this.scene.gameOver();
            }
        }
    }

    tickWavesQueue(time: number, delta: number) {
        this.missionQueue.forEach((item: EnemyWaveMissionQueue, key: number) => {
            this.missionQueue[key]['time'] -= delta;
            if (this.missionQueue[key]['time'] <= 0) {
                if (item['callback'] === 'addBehaviour') {
                    this.addBehaviour(item.data[0], item.data[1]);
                } else if (item['callback'] === 'queueMessage') {
                    this.queueMessage(item.data[0]);
                }
                this.missionQueue.splice(key, 1);
            }
        });
    }

    update(time: number, delta: number) {
        this.time = time;
        this.delta = delta;

        // this.jumpEnemy.update(time, delta);
        // this.enemy.update(time, delta);+

        if (time >= this.lastWaveCheck) {
            this.lastWaveCheck = time + WAVE_CHECK_INTERVAL;
            setTimeout(this.asyncManageWaves.bind(this));
        }
        this.tickWavesQueue(time, delta);
    }

    addCoin(enemy: any) {
        this.createCoin(enemy.x, enemy.y, 'Assets/Coin/coin_01')
            .setScale(this.getAssetScaleForPercent(0.05, APP_WIDTH, 'Assets/Coin/coin_01'))
            .play('coin');
    }


    jumpEnemy(group: Phaser.GameObjects.Group, config: any) {
        config['enemyWaves'] = this;
        this.jumps.get().createFromConfig(this.getJumpConfig(group, config), (jump: JumpEnemy) => {
            jump.destroy();
            if (undefined !== config['object']) {
                group.add((new config['object'](this.scene, config.x || 0, config.y || 0)).create(config));
            } else {
                group.get(config.x, config.y, 'atlas', this.getTextureKey(config.texture)).create(config);
            }
        });
    }

    getJumpConfig(group: Phaser.GameObjects.Group, config: {
        x: number,
        y: number,
        texture: string | Array<string>,
        scale: number,
        origin?: Array<number>,
        rotation?: number,
    }) {

        return {
            enemyWaves: this,
            objectGroup: group,
            x: config.x,
            y: config.y,
            size: Math.max(
                this.getTextureWith(config.texture),
                this.getTextureHeight(config.texture),
            ) * config.scale,
            scale: config.scale,
            texture: this.getTextureKey(config.texture),
            origin: config.origin || [],
            rotation: config.rotation || undefined,
        };
    }

    getTextureKey(texture: string | Array<string>): string {
        return Array.isArray(texture) ? texture[0] : texture;
    }

    setTextureSize(object: any, texture: string) {
        return object.setSize(
            this.scene.game.textures.get(texture).source[0].width,
            this.scene.game.textures.get(texture).source[0].height
        );
    }

    setTextureSizePercent(object: any, texture: string, percent: number) {
        object.displayWidth = this.scene.game.textures.get(texture).source[0].width * percent;
        object.displayHeight = this.scene.game.textures.get(texture).source[0].height * (this.scene.game.textures.get(texture).source[0].width * percent / this.scene.game.textures.get(texture).source[0].width);
        return object;
    }

    getTextureWith(texture: any): number {
        return getTextureWidth(this.getTextureKey(texture));
    }

    getTextureHeight(texture: any): number {
        return getTextureHeight(this.getTextureKey(texture));
    }

    getAssetScale(enemy: any): number {
        return enemy.size * APP_WIDTH / this.getTextureWith(enemy.texture);
    }

    getAssetScaleForPercent(percent: number, baseWidth: number, texture: any): number {
        return percent * baseWidth / this.getTextureWith(texture);
    }

    getAssetScaleForWidth(enemy: any, width: number): number {
        return width / this.getTextureWith(enemy.texture);
    }

    getBehaviourX(x: number | Array<number>): number {
        return Array.isArray(x) ? rand(x[0] * APP_WIDTH, x[1] * APP_WIDTH) : (
            x <= 1 ? x * APP_WIDTH : x
        );
    }

    getBehaviourY(y: number | Array<number>): number {
        return Array.isArray(y) ? rand(y[0] * APP_HEIGHT, y[1] * APP_HEIGHT) : (
            y <= 1 ? y * APP_HEIGHT : y
        );
    }

    setVelocityToPoint(object: any, point: { x: number, y: number }, speed: number) {
        const vector = this.scene.physics.velocityFromRotation(Phaser.Math.Angle.Between(
            object.x, object.y,
            point.x, point.y
        ), speed);
        object.setVelocity(vector.x, vector.y);
    }

    getTarget() {
        if (this.target.object.active) {
            return this.target.object;
        }

        let distance = 0;
        this.getEnemyGroups().forEach((group: Phaser.GameObjects.Group) => {
            group.getChildren().forEach((object: any) => {
                distance = Phaser.Math.Distance.Between(this.player.ship.x, this.player.ship.y, object.x, object.y);

                if (this.target.distance > distance) {
                    this.target.distance = distance;
                    this.target.object = object;
                }
            });
        });


        if (this.target.object.active) {
            return this.target.object;
        }

        this.target.distance = 9999;
        return this.target.object = this.defaultTarget;
    }

    hasTarget(): boolean {
        return this.getTarget().active;
    }
}
