import { APP_HEIGHT, APP_WIDTH, BEHAVIOUR_TYPE_GROUP, BEHAVIOUR_TYPE_REPEAT } from '../../../Config';
import { rand } from '../../../Tools/Functions';

export const Level1Wave4 = {
    name: 'Wave 4',
    stages: [
        {
            enemies: [
                {
                    group: 'asteroids',
                    type: 'asteroid1',
                    behaviour: [
                        {
                            type: BEHAVIOUR_TYPE_GROUP,
                            time: 0,
                            x: 0.1,
                            y: 0.05,
                            width: 0.8,
                            sizeX: 10,
                            sizeY: 2,
                            spacing: 0.35,
                            health: 30,
                        },
                        {
                            type: BEHAVIOUR_TYPE_GROUP,
                            time: 5000,
                            x: 0.25,
                            y: 0.05,
                            width: 0.5,
                            sizeX: 5,
                            sizeY: 2,
                            spacing: 0.35,
                            health: 60,
                        },
                        {
                            type: BEHAVIOUR_TYPE_REPEAT,
                            time: 2000,
                            interval: 1500,
                            repeatTimes: 10,
                            x: [0.10, 0.90],
                            y: [0.10, 0.20],
                            rotationDirection: 1,
                            rotation: 1 * rand(1, 200) / 100,
                            point: { x: rand(APP_WIDTH * 0.4, APP_WIDTH * 0.6), y: APP_HEIGHT, speed: 200 },
                            health: 60,
                        },
                    ],
                },
            ],
        }
    ],
}