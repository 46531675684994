import React, { Component } from 'react';
import { APP_WIDTH } from '../../Config';
import { imagePath } from '../../Tools/Functions';
import GoBack from '../Parts/GoBack';
import { MISSIONS } from '../../Game/GameConfig';


const positions = [
    {
        scale: 1,
        width: APP_WIDTH * 0.8,
        top: '50%',
        left: '50%',
    },
    {
        scale: 0.65,
        width: APP_WIDTH * 0.8,
        top: '25%',
        left: '65%',
    },
    {
        scale: 0.30,
        width: APP_WIDTH * 0.8,
        top: '12%',
        left: '72.5%',
    }
];

class MissionsPage extends Component {

    render() {
        let position;
        const { manager, gameManager } = this.props;

        return (
            <div>
                <GoBack onClick={() => {
                    manager.setPage('home');
                }} />
                <img
                    src={imagePath(require('../../Game/Assets/UI/BigSun.png'))}
                    alt=""
                    width={APP_WIDTH}
                    height="auto"
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                    }}
                />

                {MISSIONS.map((mission, key) => {
                    position = positions[key];
                    return (
                        <div key={key} style={{
                            display: 'inline-block',
                            position: 'absolute',
                            top: position.top,
                            left: position.left,
                            transform: 'translate(-50%,-50%)',
                            zIndex: 9999 - key,
                        }} onClick={() => {
                            gameManager.startMission(key);
                        }}>
                            <div style={{
                                transform: 'scale(' + position.scale + ')',
                            }}>
                                <img
                                    src={mission.planet}
                                    alt=""
                                    width={position.width}
                                    height="auto"
                                    style={{
                                    }}
                                />

                                <img
                                    alt=""
                                    src={mission.overlay}
                                    width={position.width}
                                    height={'auto'}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        transform: 'rotate(130deg)',
                                    }}
                                />

                                <div style={{
                                    background: 'url(' + imagePath(require('../../Game/Assets/UI/Pattern.png')) + ') repeat repeat',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '15%',
                                    width: '60%',
                                    padding: '5%',
                                    transform: 'translate(0,-50%)',
                                }}>
                                    <div className="OpenSans-ExtraBold" style={{
                                        color: '#fe1732',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontSize: '5vw',
                                        textShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
                                        padding: '10%, 0 10%, 0',
                                        borderTop: '0.5vw solid #fe1732',
                                        borderBottom: '0.5vw solid #fe1732',
                                    }}>
                                        {mission.name}
                                    </div>
                                </div>



                            </div>
                        </div>

                    );
                })}
            </div>
        );
    }
}

export default MissionsPage;
