import React, { Component } from 'react';
import { imagePath } from '../../Tools/Functions';

class MainMenu extends Component {

    render() {
        const { manager } = this.props;
        return (
            <div id="main-menu" style={{
                width: '100%',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
                position: 'absolute'
            }}>
                <div className="menu-item OpenSans-ExtraBold" onClick={() => {
                    manager.setPage('missions');
                }}>
                    <div className="menu-item-line">
                        <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                            width: '100%',
                            heigh: 'auto',
                        }} />
                    </div>
                    Missions
                    <div className="menu-item-line">
                        <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                            width: '100%',
                            heigh: 'auto',
                        }} />
                    </div>
                </div>

                <div className="menu-item OpenSans-ExtraBold" onClick={() => {
                    manager.setPage('engineering');
                }}>
                    <div className="menu-item-line">
                        <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                            width: '100%',
                            heigh: 'auto',
                        }} />
                    </div>
                    Engineering
                    <div className="menu-item-line">
                        <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                            width: '100%',
                            heigh: 'auto',
                        }} />
                    </div>
                </div>

                <div className="menu-item OpenSans-ExtraBold" onClick={() => {
                    manager.setPage('settings');
                }}>
                    <div className="menu-item-line">
                        <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                            width: '100%',
                            heigh: 'auto',
                        }} />
                    </div>
                    Settings
                    <div className="menu-item-line">
                        <img alt="" src={imagePath(require('../../Game/Assets/UI/menuitemLine.png'))} style={{
                            width: '100%',
                            heigh: 'auto',
                        }} />
                    </div>
                </div>

            </div>
        );
    }
}

export default MainMenu;
